@charset "UTF-8";
/********** [ -start- NECESSARY STYLES FOR EVERY PAGE -start- ] **********/
/* Mixin Aspect ratio 2.0 */
/*# Typography #*/
/* Predefined Sizes */
/* Header */
/* Material Transitions */
/* breakpoint notebook */
/* common colors */
/* corporative colors */
/* font colors */
/* misc colors */
/* widget colors */
/* LBP */
/* Colores -Temas,Agrupadores,Categorias- */
/* social colors */
/* Albert Sans */
/* Work Sans */
@font-face {
  font-family: "Work Sans";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-300.eot?") format("eot"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-300.woff2") format("woff2"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-300.woff") format("woff"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-300.ttf") format("truetype"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-300.svg#Work_Sans") format("svg"); }

@font-face {
  font-family: "Work Sans";
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-300italic.eot?") format("eot"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-300italic.woff2") format("woff2"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-300italic.woff") format("woff"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-300italic.ttf") format("truetype"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-300italic.svg#Work_Sans") format("svg"); }

@font-face {
  font-family: "Work Sans";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-500.eot?") format("eot"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-500.woff2") format("woff2"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-500.woff") format("woff"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-500.ttf") format("truetype"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-500.svg#Work_Sans") format("svg"); }

@font-face {
  font-family: "Work Sans";
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-500italic.eot?") format("eot"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-500italic.woff2") format("woff2"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-500italic.woff") format("woff"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-500italic.ttf") format("truetype"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-500italic.svg#Work_Sans") format("svg"); }

@font-face {
  font-family: "Work Sans";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-600.eot?") format("eot"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-600.woff2") format("woff2"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-600.woff") format("woff"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-600.ttf") format("truetype"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-600.svg#Work_Sans") format("svg"); }

@font-face {
  font-family: "Work Sans";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-700.eot?") format("eot"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-700.woff2") format("woff2"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-700.woff") format("woff"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-700.ttf") format("truetype"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-700.svg#Work_Sans") format("svg"); }

@font-face {
  font-family: "Work Sans";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-italic.eot?") format("eot"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-italic.woff2") format("woff2"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-italic.woff") format("woff"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-italic.ttf") format("truetype"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-italic.svg#Work_Sans") format("svg"); }

@font-face {
  font-family: "Work Sans";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-regular.eot?") format("eot"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-regular.woff2") format("woff2"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-regular.woff") format("woff"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-regular.ttf") format("truetype"), url("https://static.thinkindot.com/fonts/work-sans/work-sans-v17-latin-regular.svg#Work_Sans") format("svg"); }

/* Red Hat Display */
@font-face {
  font-family: "Red Hat Display";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-700.eot?") format("eot"), url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-700.woff2") format("woff2"), url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-700.woff") format("woff"), url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-700.ttf") format("truetype"), url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-700.svg#Red_Hat_Display") format("svg"); }

@font-face {
  font-family: "Red Hat Display";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-600.eot?") format("eot"), url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-600.woff2") format("woff2"), url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-600.woff") format("woff"), url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-600.ttf") format("truetype"), url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-600.svg#Red_Hat_Display") format("svg"); }

@font-face {
  font-family: "Red Hat Display";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-500.eot?") format("eot"), url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-500.woff2") format("woff2"), url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-500.woff") format("woff"), url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-500.ttf") format("truetype"), url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-500.svg#Red_Hat_Display") format("svg"); }

@font-face {
  font-family: "Red Hat Display";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-regular.eot?") format("eot"), url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-regular.woff2") format("woff2"), url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-regular.woff") format("woff"), url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-regular.ttf") format("truetype"), url("https://static.thinkindot.com/fonts/red-hat-display/red-hat-display-v14-latin-regular.svg#Red_Hat_Display") format("svg"); }

i[class^='icon'] {
  line-height: 1;
  display: inline-flex;
  min-width: 1rem;
  min-height: 1rem;
  vertical-align: middle;
  mask-size: auto 100%;
  -webkit-mask-size: auto 100%;
  -o-object-fit: cover;
     object-fit: cover; }
  i[class^='icon'][class*='primary'] {
    background-color: #0994DC; }
  i[class^='icon'][class*='primary-alt'] {
    background-color: #069; }
  i[class^='icon'][class*='red'] {
    background-color: #EB5757; }
  i[class^='icon'][class*='green'] {
    background-color: #27AE60; }
  i[class^='icon'][class*='dark'] {
    background-color: #121212; }
  i[class^='icon'][class*='dark-gray'] {
    background-color: #424242; }
  i[class^='icon'][class*='medium-gray'] {
    background-color: #828282; }
  i[class^='icon'][class*='light-gray'] {
    background-color: #F2F2F2; }
  i[class^='icon'][class*='white'] {
    background-color: #FFF; }

i[class^='icon'][class*='share'] {
  mask: url("/css-custom/239/v3/icons/share.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/share.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='alert'] {
  mask: url("/css-custom/239/v3/icons/alert.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/alert.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='arrow-left'] {
  mask: url("/css-custom/239/v3/icons/arrow-left.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/arrow-left.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='arrow-right'] {
  mask: url("/css-custom/239/v3/icons/arrow-right.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/arrow-right.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='bookmark'] {
  mask: url("/css-custom/239/v3/icons/bookmark.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/bookmark.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='chevron-down'] {
  mask: url("/css-custom/239/v3/icons/chevron-down.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/chevron-down.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='chevron-left'] {
  mask: url("/css-custom/239/v3/icons/chevron-left.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/chevron-left.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='chevron-right'] {
  mask: url("/css-custom/239/v3/icons/chevron-right.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/chevron-right.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='clock'] {
  mask: url("/css-custom/239/v3/icons/clock.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/clock.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='close-x'] {
  mask: url("/css-custom/239/v3/icons/close-x.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/close-x.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='comment'] {
  mask: url("/css-custom/239/v3/icons/comment.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/comment.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='envelope'] {
  mask: url("/css-custom/239/v3/icons/envelope.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/envelope.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='external-link'] {
  mask: url("/css-custom/239/v3/icons/external-link.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/external-link.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='facebook-f'] {
  mask: url("/css-custom/239/v3/icons/facebook-f.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/facebook-f.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='facebook-square'] {
  mask: url("/css-custom/239/v3/icons/facebook-square.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/facebook-square.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='info'] {
  mask: url("/css-custom/239/v3/icons/info.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/info.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='instagram'] {
  mask: url("/css-custom/239/v3/icons/instagram.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/instagram.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='journalist'] {
  mask: url("/css-custom/239/v3/icons/journalist.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/journalist.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='phone'] {
  mask: url("/css-custom/239/v3/icons/phone.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/phone.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='play-circle'] {
  mask: url("/css-custom/239/v3/icons/play-circle.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/play-circle.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='play'] {
  mask: url("/css-custom/239/v3/icons/play.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/play.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='facebook-circle'] {
  mask: url("/css-custom/239/v3/icons/facebook-circle.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/facebook-circle.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='rss'] {
  mask: url("/css-custom/239/v3/icons/rss.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/rss.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='search'] {
  mask: url("/css-custom/239/v3/icons/search.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/search.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='telegram'] {
  mask: url("/css-custom/239/v3/icons/telegram.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/telegram.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='telegram-circle'] {
  mask: url("/css-custom/239/v3/icons/telegram-circle.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/telegram-circle.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='twitter'] {
  mask: url("/css-custom/239/v3/icons/twitter.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/twitter.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='video'] {
  mask: url("/css-custom/239/v3/icons/video.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/video.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='volume'] {
  mask: url("/css-custom/239/v3/icons/volume.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/volume.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='whatsapp'] {
  mask: url("/css-custom/239/v3/icons/whatsapp.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/whatsapp.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='youtube'] {
  mask: url("/css-custom/239/v3/icons/youtube.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/youtube.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='user'] {
  mask: url("/css-custom/239/v3/icons/user.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/user.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='star'] {
  mask: url("/css-custom/239/v3/icons/star.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/star.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='linkedin'] {
  mask: url("/css-custom/239/v3/icons/linkedin.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/linkedin.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='linkedin-square'] {
  mask: url("/css-custom/239/v3/icons/linkedin-square.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/linkedin-square.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='download'] {
  mask: url("/css-custom/239/v3/icons/download.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/download.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='mail'] {
  mask: url("/css-custom/239/v3/icons/mail.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/mail.svg") no-repeat center/auto 100%; }

i[class^='icon'][class*='instagram-alt'] {
  mask: url("/css-custom/239/v3/icons/instagram-alt.svg") no-repeat center/auto 100%;
  -webkit-mask: url("/css-custom/239/v3/icons/instagram-alt.svg") no-repeat center/auto 100%; }

/* Extra-extra large
≥1366px */
/* Extra large
≥1280px */
/* Large
≥992px */
/* Medium
≥768px */
/* Small
≥576px */
/* Extra small
<576px */
/* containers width */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #EB5757;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #27AE60;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #FFF;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0994DC;
  --bs-secondary: #6c757d;
  --bs-success: #27AE60;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #EB5757;
  --bs-light: #f8f9fa;
  --bs-dark: #121212;
  --bs-primary-rgb: 9, 148, 220;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 39, 174, 96;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 235, 87, 87;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 18, 18, 18;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #FFF;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0994DC;
  --bs-link-hover-color: #0776b0;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd; }

.container,
.container-fluid {
  --bs-gutter-x: 1.25rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 728px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1280px) {
  .container {
    max-width: 1280px; } }

@media (min-width: 1366px) {
  .container {
    max-width: 1330px; } }

.row {
  --bs-gutter-x: 1.25rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.g-1 {
  --bs-gutter-x: 0.25rem; }

.g-1 {
  --bs-gutter-y: 0.25rem; }

.g-2 {
  --bs-gutter-x: 0.5rem; }

.g-2 {
  --bs-gutter-y: 0.5rem; }

@media (min-width: 576px) {
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; } }

@media (min-width: 992px) {
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; } }

@media (min-width: 1280px) {
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; } }

.d-block {
  display: block !important; }

.d-flex {
  display: flex !important; }

.d-none {
  display: none !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-center {
  align-content: center !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-5 {
  margin: 3rem !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

@media (min-width: 576px) {
  .d-sm-flex {
    display: flex !important; }
  .d-sm-none {
    display: none !important; } }

@media (min-width: 768px) {
  .d-md-block {
    display: block !important; }
  .d-md-flex {
    display: flex !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-center {
    align-items: center !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; } }

@media (min-width: 992px) {
  .d-lg-flex {
    display: flex !important; } }

@media (min-width: 1280px) {
  .d-xl-flex {
    display: flex !important; } }

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  border: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

button,
input[type='submit'],
input[type='reset'],
input[type='text'],
input[type='search'],
input[type='email'],
input[type='tel'],
a[role='button'] {
  font: inherit;
  padding: 0;
  cursor: pointer;
  color: inherit;
  border: none;
  outline: none !important;
  outline: 0 !important;
  background: none;
  box-shadow: none;
  -moz-outline-style: none !important; }
  button:active, button:focus, button:hover, button:visited,
  input[type='submit']:active,
  input[type='submit']:focus,
  input[type='submit']:hover,
  input[type='submit']:visited,
  input[type='reset']:active,
  input[type='reset']:focus,
  input[type='reset']:hover,
  input[type='reset']:visited,
  input[type='text']:active,
  input[type='text']:focus,
  input[type='text']:hover,
  input[type='text']:visited,
  input[type='search']:active,
  input[type='search']:focus,
  input[type='search']:hover,
  input[type='search']:visited,
  input[type='email']:active,
  input[type='email']:focus,
  input[type='email']:hover,
  input[type='email']:visited,
  input[type='tel']:active,
  input[type='tel']:focus,
  input[type='tel']:hover,
  input[type='tel']:visited,
  a[role='button']:active,
  a[role='button']:focus,
  a[role='button']:hover,
  a[role='button']:visited {
    border: none;
    outline: none !important;
    outline: 0 !important;
    box-shadow: none;
    -moz-outline-style: none !important; }

a {
  color: inherit; }
  a:active, a:hover, a:link, a:visited {
    text-decoration: none;
    color: inherit; }

.navbar {
  justify-content: flex-start;
  padding: 0; }

textarea,
select,
input,
button {
  text-indent: 1px;
  text-overflow: '';
  border: 0 none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none; }
  textarea:focus,
  select:focus,
  input:focus,
  button:focus {
    text-indent: 1px;
    text-overflow: '';
    outline: 0;
    box-shadow: none !important;
    -webkit-appearance: none;
    -moz-appearance: none; }

body,
html {
  font-family: "Work Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  max-width: 100vw;
  background-color: #FFF;
  scroll-behavior: smooth; }
  @media screen and (max-width: 575px) {
    body,
    html {
      overflow-x: hidden; } }

body {
  overflow-x: hidden; }
  body .residual-container {
    height: inherit; }
    body .residual-container:empty {
      display: none; }

.extended-background {
  position: relative;
  z-index: 0; }
  .extended-background:before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: calc(((100vw - (100% + (300px + 1.25rem))) / -2) - ((300px) + 1.25rem));
    bottom: 0;
    left: calc((100vw - (100% + (300px + 1.25rem))) / -2);
    display: block;
    content: '';
    background-color: inherit; }

.simple-extended-background {
  position: relative;
  z-index: 0; }
  .simple-extended-background:before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: calc(-1 * ((100vw - 100%) / 2));
    bottom: 0;
    left: calc(-1 * ((100vw - 100%) / 2));
    display: block;
    content: '';
    background-color: inherit; }

.shadowed {
  box-shadow: 0 2px 8px rgba(51, 51, 51, 0.1);
  -webkit-box-shadow: 0 2px 8px rgba(51, 51, 51, 0.1);
  -moz-box-shadow: 0 2px 8px rgba(51, 51, 51, 0.1); }

.hide {
  display: none; }

.container {
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: margin; }

.img-fluid {
  vertical-align: bottom; }

.row {
  /* reglas para alinear todo al elemento más alto */ }
  .row > [class^='col']:empty,
  .row > [class*='col-']:empty {
    display: none; }
  .row--eq-height [class^='col'] {
    display: flex;
    flex-direction: column; }
    .row--eq-height [class^='col'] .news-article:not([class*='landscape']) {
      flex: 1 0 auto; }

[class^='container']:not(.container-fluid):not(.container-sm):not(.container-md):not(.container-lg):not(.container-xl):not(.container-xxl) [class^='container'] {
  width: inherit !important;
  max-width: inherit !important;
  padding: 0 !important; }
.vjs-hidden {
  display: none !important; }

.aspect-169 {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; }
  .aspect-169 > *:first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }

.free-text > * strong {
  font-weight: 700; }

.free-text > * a {
  color: #069; }

/* .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    &::before {
        display: block;
        content: "";
    }

    .embed-responsive-item, iframe, embed, object, video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.embed-responsive-21by9::before {
    padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
} */
/* Widges con colores de fondo */
.negro-bg {
  background-color: #1A1A1D; }
  .negro-bg .highlighted-note-square-image .preview,
  .negro-bg .highlighted-note-square-image .title,
  .negro-bg .highlighted-note-square-image .author,
  .negro-bg .highlighted-note-square-image .article-category-grouper,
  .negro-bg .highlighted-note-square-image .article-kicker,
  .negro-bg .simple-note .preview,
  .negro-bg .simple-note .title,
  .negro-bg .simple-note .author,
  .negro-bg .simple-note .article-category-grouper,
  .negro-bg .simple-note .article-kicker,
  .negro-bg .simple-note-square-image .preview,
  .negro-bg .simple-note-square-image .title,
  .negro-bg .simple-note-square-image .author,
  .negro-bg .simple-note-square-image .article-category-grouper,
  .negro-bg .simple-note-square-image .article-kicker {
    color: #FFF !important; }
  .negro-bg .headline {
    margin-top: 0;
    padding-top: 8px; }
    .negro-bg .headline .obj-title {
      color: #FFF !important; }
  .negro-bg.negro {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .negro-bg.negro [class*='note'] .article-theme {
      background-color: rgba(26, 26, 29, 0.8) !important;
      color: #FFF !important; }
    .negro-bg.negro [data-type*="article"] i[class*="play"]:hover {
      color: #1A1A1D !important; }
  .negro-bg.rojo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .negro-bg.rojo [class*='note'] .article-theme {
      background-color: rgba(211, 47, 47, 0.8) !important;
      color: #FFF !important; }
    .negro-bg.rojo [data-type*="article"] i[class*="play"]:hover {
      color: #D32F2F !important; }
  .negro-bg.marron {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .negro-bg.marron [class*='note'] .article-theme {
      background-color: rgba(121, 85, 72, 0.8) !important;
      color: #FFF !important; }
    .negro-bg.marron [data-type*="article"] i[class*="play"]:hover {
      color: #795548 !important; }
  .negro-bg.verde {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .negro-bg.verde [class*='note'] .article-theme {
      background-color: rgba(136, 178, 68, 0.8) !important;
      color: #FFF !important; }
    .negro-bg.verde [data-type*="article"] i[class*="play"]:hover {
      color: #88B244 !important; }
  .negro-bg.ocre {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .negro-bg.ocre [class*='note'] .article-theme {
      background-color: rgba(237, 217, 192, 0.8) !important;
      color: #000 !important; }
    .negro-bg.ocre [data-type*="article"] i[class*="play"]:hover {
      color: #EDD9C0 !important; }
  .negro-bg.gris {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .negro-bg.gris [class*='note'] .article-theme {
      background-color: rgba(158, 158, 158, 0.8) !important;
      color: #FFF !important; }
    .negro-bg.gris [data-type*="article"] i[class*="play"]:hover {
      color: #9E9E9E !important; }
  .negro-bg.bordo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .negro-bg.bordo [class*='note'] .article-theme {
      background-color: rgba(118, 50, 63, 0.8) !important;
      color: #FFF !important; }
    .negro-bg.bordo [data-type*="article"] i[class*="play"]:hover {
      color: #76323F !important; }
  .negro-bg.azul {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .negro-bg.azul [class*='note'] .article-theme {
      background-color: rgba(48, 63, 159, 0.8) !important;
      color: #FFF !important; }
    .negro-bg.azul [data-type*="article"] i[class*="play"]:hover {
      color: #303F9F !important; }
  .negro-bg.celeste {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .negro-bg.celeste [class*='note'] .article-theme {
      background-color: rgba(3, 169, 244, 0.8) !important;
      color: #FFF !important; }
    .negro-bg.celeste [data-type*="article"] i[class*="play"]:hover {
      color: #03A9F4 !important; }
  .negro-bg.amarillo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .negro-bg.amarillo [class*='note'] .article-theme {
      background-color: rgba(247, 183, 51, 0.8) !important;
      color: #FFF !important; }
    .negro-bg.amarillo [data-type*="article"] i[class*="play"]:hover {
      color: #F7B733 !important; }
  .negro-bg.violeta {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .negro-bg.violeta [class*='note'] .article-theme {
      background-color: rgba(103, 58, 183, 0.8) !important;
      color: #FFF !important; }
    .negro-bg.violeta [data-type*="article"] i[class*="play"]:hover {
      color: #673AB7 !important; }
  .negro-bg.texto-blanco .article-shouts .info-wrapper .preview,
  .negro-bg.texto-blanco .article-shouts .info-wrapper .title,
  .negro-bg.texto-blanco .article-shouts .info-wrapper .author,
  .negro-bg.texto-blanco .article-shouts .info-wrapper .article-kicker,
  .negro-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview,
  .negro-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title,
  .negro-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author,
  .negro-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker,
  .negro-bg.texto-blanco .simple-list .info-wrapper .preview,
  .negro-bg.texto-blanco .simple-list .info-wrapper .title,
  .negro-bg.texto-blanco .simple-list .info-wrapper .author,
  .negro-bg.texto-blanco .simple-list .info-wrapper .article-kicker,
  .negro-bg.texto-blanco .simple-list-square-image .info-wrapper .preview,
  .negro-bg.texto-blanco .simple-list-square-image .info-wrapper .title,
  .negro-bg.texto-blanco .simple-list-square-image .info-wrapper .author,
  .negro-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker {
    color: #FFF !important; }
    .negro-bg.texto-blanco .article-shouts .info-wrapper .preview:hover,
    .negro-bg.texto-blanco .article-shouts .info-wrapper .title:hover,
    .negro-bg.texto-blanco .article-shouts .info-wrapper .author:hover,
    .negro-bg.texto-blanco .article-shouts .info-wrapper .article-kicker:hover,
    .negro-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview:hover,
    .negro-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title:hover,
    .negro-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author:hover,
    .negro-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker:hover,
    .negro-bg.texto-blanco .simple-list .info-wrapper .preview:hover,
    .negro-bg.texto-blanco .simple-list .info-wrapper .title:hover,
    .negro-bg.texto-blanco .simple-list .info-wrapper .author:hover,
    .negro-bg.texto-blanco .simple-list .info-wrapper .article-kicker:hover,
    .negro-bg.texto-blanco .simple-list-square-image .info-wrapper .preview:hover,
    .negro-bg.texto-blanco .simple-list-square-image .info-wrapper .title:hover,
    .negro-bg.texto-blanco .simple-list-square-image .info-wrapper .author:hover,
    .negro-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker:hover {
      color: #FFF !important; }
  .negro-bg.texto-blanco .article-shouts .preview,
  .negro-bg.texto-blanco .article-shouts .title,
  .negro-bg.texto-blanco .article-shouts .author,
  .negro-bg.texto-blanco .highlighted-note-square-image .preview,
  .negro-bg.texto-blanco .highlighted-note-square-image .title,
  .negro-bg.texto-blanco .highlighted-note-square-image .author,
  .negro-bg.texto-blanco .simple-list .preview,
  .negro-bg.texto-blanco .simple-list .title,
  .negro-bg.texto-blanco .simple-list .author,
  .negro-bg.texto-blanco .simple-list-square-image .preview,
  .negro-bg.texto-blanco .simple-list-square-image .title,
  .negro-bg.texto-blanco .simple-list-square-image .author {
    color: #FFF !important; }
    .negro-bg.texto-blanco .article-shouts .preview:hover,
    .negro-bg.texto-blanco .article-shouts .title:hover,
    .negro-bg.texto-blanco .article-shouts .author:hover,
    .negro-bg.texto-blanco .highlighted-note-square-image .preview:hover,
    .negro-bg.texto-blanco .highlighted-note-square-image .title:hover,
    .negro-bg.texto-blanco .highlighted-note-square-image .author:hover,
    .negro-bg.texto-blanco .simple-list .preview:hover,
    .negro-bg.texto-blanco .simple-list .title:hover,
    .negro-bg.texto-blanco .simple-list .author:hover,
    .negro-bg.texto-blanco .simple-list-square-image .preview:hover,
    .negro-bg.texto-blanco .simple-list-square-image .title:hover,
    .negro-bg.texto-blanco .simple-list-square-image .author:hover {
      color: #FFF !important; }
  .negro-bg.texto-blanco .standard-header {
    border-bottom-color: #FFF !important; }
    .negro-bg.texto-blanco .standard-header__title {
      color: #FFF !important; }
  .negro-bg.texto-blanco [class*='note'] .article-theme {
    color: #FFF !important; }
  .negro-bg.texto-blanco .headline {
    margin-top: 0;
    padding-top: 8px; }
    .negro-bg.texto-blanco .headline .obj-title {
      color: #FFF !important; }

/* Widgets con color relacionado */

.widget-wrapper.negro:not([class*="-bg"]) {
  /* Cabezales */
  /* Chapitas en los listados de notas */
  /* Icono de Play si la nota tiene video */ }
  .widget-wrapper.negro:not([class*="-bg"]) .headline .obj-title {
    color: #1A1A1D; }
  .widget-wrapper.negro:not([class*="-bg"]) [class*='note']:not(.highlighted-portrait-note):not(.highlighted-note-overprinted-title) .article-kicker {
    color: #1A1A1D !important; }
  .widget-wrapper.negro:not([class*="-bg"]) [class*='note'] .article-theme {
    background-color: rgba(26, 26, 29, 0.8) !important;
    color: #FFF !important; }
  .widget-wrapper.negro:not([class*="-bg"]) [class*='note'] .info-wrapper .preview:hover,
  .widget-wrapper.negro:not([class*="-bg"]) [class*='note'] .info-wrapper .title:hover,
  .widget-wrapper.negro:not([class*="-bg"]) [class*='note'] .info-wrapper .author:hover,
  .widget-wrapper.negro:not([class*="-bg"]) [class*='note'] .info-wrapper .article-category-grouper:hover,
  .widget-wrapper.negro:not([class*="-bg"]) [class*='note'] .info-wrapper .article-kicker:hover {
    color: #1A1A1D !important; }
  .widget-wrapper.negro:not([class*="-bg"]) [class*='note'] .preview:hover,
  .widget-wrapper.negro:not([class*="-bg"]) [class*='note'] .title:hover,
  .widget-wrapper.negro:not([class*="-bg"]) [class*='note'] .author:hover {
    color: #1A1A1D !important; }
  .widget-wrapper.negro:not([class*="-bg"]) [data-type*="article"] i[class*="play"]:hover {
    color: #1A1A1D !important; }

/* Widges con colores de fondo */
.rojo-bg {
  background-color: #D32F2F; }
  .rojo-bg .highlighted-note-square-image .preview,
  .rojo-bg .highlighted-note-square-image .title,
  .rojo-bg .highlighted-note-square-image .author,
  .rojo-bg .highlighted-note-square-image .article-category-grouper,
  .rojo-bg .highlighted-note-square-image .article-kicker,
  .rojo-bg .simple-note .preview,
  .rojo-bg .simple-note .title,
  .rojo-bg .simple-note .author,
  .rojo-bg .simple-note .article-category-grouper,
  .rojo-bg .simple-note .article-kicker,
  .rojo-bg .simple-note-square-image .preview,
  .rojo-bg .simple-note-square-image .title,
  .rojo-bg .simple-note-square-image .author,
  .rojo-bg .simple-note-square-image .article-category-grouper,
  .rojo-bg .simple-note-square-image .article-kicker {
    color: #FFF !important; }
  .rojo-bg .headline {
    margin-top: 0;
    padding-top: 8px; }
    .rojo-bg .headline .obj-title {
      color: #FFF !important; }
  .rojo-bg.negro {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .rojo-bg.negro [class*='note'] .article-theme {
      background-color: rgba(26, 26, 29, 0.8) !important;
      color: #FFF !important; }
    .rojo-bg.negro [data-type*="article"] i[class*="play"]:hover {
      color: #1A1A1D !important; }
  .rojo-bg.rojo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .rojo-bg.rojo [class*='note'] .article-theme {
      background-color: rgba(211, 47, 47, 0.8) !important;
      color: #FFF !important; }
    .rojo-bg.rojo [data-type*="article"] i[class*="play"]:hover {
      color: #D32F2F !important; }
  .rojo-bg.marron {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .rojo-bg.marron [class*='note'] .article-theme {
      background-color: rgba(121, 85, 72, 0.8) !important;
      color: #FFF !important; }
    .rojo-bg.marron [data-type*="article"] i[class*="play"]:hover {
      color: #795548 !important; }
  .rojo-bg.verde {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .rojo-bg.verde [class*='note'] .article-theme {
      background-color: rgba(136, 178, 68, 0.8) !important;
      color: #FFF !important; }
    .rojo-bg.verde [data-type*="article"] i[class*="play"]:hover {
      color: #88B244 !important; }
  .rojo-bg.ocre {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .rojo-bg.ocre [class*='note'] .article-theme {
      background-color: rgba(237, 217, 192, 0.8) !important;
      color: #000 !important; }
    .rojo-bg.ocre [data-type*="article"] i[class*="play"]:hover {
      color: #EDD9C0 !important; }
  .rojo-bg.gris {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .rojo-bg.gris [class*='note'] .article-theme {
      background-color: rgba(158, 158, 158, 0.8) !important;
      color: #FFF !important; }
    .rojo-bg.gris [data-type*="article"] i[class*="play"]:hover {
      color: #9E9E9E !important; }
  .rojo-bg.bordo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .rojo-bg.bordo [class*='note'] .article-theme {
      background-color: rgba(118, 50, 63, 0.8) !important;
      color: #FFF !important; }
    .rojo-bg.bordo [data-type*="article"] i[class*="play"]:hover {
      color: #76323F !important; }
  .rojo-bg.azul {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .rojo-bg.azul [class*='note'] .article-theme {
      background-color: rgba(48, 63, 159, 0.8) !important;
      color: #FFF !important; }
    .rojo-bg.azul [data-type*="article"] i[class*="play"]:hover {
      color: #303F9F !important; }
  .rojo-bg.celeste {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .rojo-bg.celeste [class*='note'] .article-theme {
      background-color: rgba(3, 169, 244, 0.8) !important;
      color: #FFF !important; }
    .rojo-bg.celeste [data-type*="article"] i[class*="play"]:hover {
      color: #03A9F4 !important; }
  .rojo-bg.amarillo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .rojo-bg.amarillo [class*='note'] .article-theme {
      background-color: rgba(247, 183, 51, 0.8) !important;
      color: #FFF !important; }
    .rojo-bg.amarillo [data-type*="article"] i[class*="play"]:hover {
      color: #F7B733 !important; }
  .rojo-bg.violeta {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .rojo-bg.violeta [class*='note'] .article-theme {
      background-color: rgba(103, 58, 183, 0.8) !important;
      color: #FFF !important; }
    .rojo-bg.violeta [data-type*="article"] i[class*="play"]:hover {
      color: #673AB7 !important; }
  .rojo-bg.texto-blanco .article-shouts .info-wrapper .preview,
  .rojo-bg.texto-blanco .article-shouts .info-wrapper .title,
  .rojo-bg.texto-blanco .article-shouts .info-wrapper .author,
  .rojo-bg.texto-blanco .article-shouts .info-wrapper .article-kicker,
  .rojo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview,
  .rojo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title,
  .rojo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author,
  .rojo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker,
  .rojo-bg.texto-blanco .simple-list .info-wrapper .preview,
  .rojo-bg.texto-blanco .simple-list .info-wrapper .title,
  .rojo-bg.texto-blanco .simple-list .info-wrapper .author,
  .rojo-bg.texto-blanco .simple-list .info-wrapper .article-kicker,
  .rojo-bg.texto-blanco .simple-list-square-image .info-wrapper .preview,
  .rojo-bg.texto-blanco .simple-list-square-image .info-wrapper .title,
  .rojo-bg.texto-blanco .simple-list-square-image .info-wrapper .author,
  .rojo-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker {
    color: #FFF !important; }
    .rojo-bg.texto-blanco .article-shouts .info-wrapper .preview:hover,
    .rojo-bg.texto-blanco .article-shouts .info-wrapper .title:hover,
    .rojo-bg.texto-blanco .article-shouts .info-wrapper .author:hover,
    .rojo-bg.texto-blanco .article-shouts .info-wrapper .article-kicker:hover,
    .rojo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview:hover,
    .rojo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title:hover,
    .rojo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author:hover,
    .rojo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker:hover,
    .rojo-bg.texto-blanco .simple-list .info-wrapper .preview:hover,
    .rojo-bg.texto-blanco .simple-list .info-wrapper .title:hover,
    .rojo-bg.texto-blanco .simple-list .info-wrapper .author:hover,
    .rojo-bg.texto-blanco .simple-list .info-wrapper .article-kicker:hover,
    .rojo-bg.texto-blanco .simple-list-square-image .info-wrapper .preview:hover,
    .rojo-bg.texto-blanco .simple-list-square-image .info-wrapper .title:hover,
    .rojo-bg.texto-blanco .simple-list-square-image .info-wrapper .author:hover,
    .rojo-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker:hover {
      color: #FFF !important; }
  .rojo-bg.texto-blanco .article-shouts .preview,
  .rojo-bg.texto-blanco .article-shouts .title,
  .rojo-bg.texto-blanco .article-shouts .author,
  .rojo-bg.texto-blanco .highlighted-note-square-image .preview,
  .rojo-bg.texto-blanco .highlighted-note-square-image .title,
  .rojo-bg.texto-blanco .highlighted-note-square-image .author,
  .rojo-bg.texto-blanco .simple-list .preview,
  .rojo-bg.texto-blanco .simple-list .title,
  .rojo-bg.texto-blanco .simple-list .author,
  .rojo-bg.texto-blanco .simple-list-square-image .preview,
  .rojo-bg.texto-blanco .simple-list-square-image .title,
  .rojo-bg.texto-blanco .simple-list-square-image .author {
    color: #FFF !important; }
    .rojo-bg.texto-blanco .article-shouts .preview:hover,
    .rojo-bg.texto-blanco .article-shouts .title:hover,
    .rojo-bg.texto-blanco .article-shouts .author:hover,
    .rojo-bg.texto-blanco .highlighted-note-square-image .preview:hover,
    .rojo-bg.texto-blanco .highlighted-note-square-image .title:hover,
    .rojo-bg.texto-blanco .highlighted-note-square-image .author:hover,
    .rojo-bg.texto-blanco .simple-list .preview:hover,
    .rojo-bg.texto-blanco .simple-list .title:hover,
    .rojo-bg.texto-blanco .simple-list .author:hover,
    .rojo-bg.texto-blanco .simple-list-square-image .preview:hover,
    .rojo-bg.texto-blanco .simple-list-square-image .title:hover,
    .rojo-bg.texto-blanco .simple-list-square-image .author:hover {
      color: #FFF !important; }
  .rojo-bg.texto-blanco .standard-header {
    border-bottom-color: #FFF !important; }
    .rojo-bg.texto-blanco .standard-header__title {
      color: #FFF !important; }
  .rojo-bg.texto-blanco [class*='note'] .article-theme {
    color: #FFF !important; }
  .rojo-bg.texto-blanco .headline {
    margin-top: 0;
    padding-top: 8px; }
    .rojo-bg.texto-blanco .headline .obj-title {
      color: #FFF !important; }

/* Widgets con color relacionado */

.widget-wrapper.rojo:not([class*="-bg"]) {
  /* Cabezales */
  /* Chapitas en los listados de notas */
  /* Icono de Play si la nota tiene video */ }
  .widget-wrapper.rojo:not([class*="-bg"]) .headline .obj-title {
    color: #D32F2F; }
  .widget-wrapper.rojo:not([class*="-bg"]) [class*='note']:not(.highlighted-portrait-note):not(.highlighted-note-overprinted-title) .article-kicker {
    color: #D32F2F !important; }
  .widget-wrapper.rojo:not([class*="-bg"]) [class*='note'] .article-theme {
    background-color: rgba(211, 47, 47, 0.8) !important;
    color: #FFF !important; }
  .widget-wrapper.rojo:not([class*="-bg"]) [class*='note'] .info-wrapper .preview:hover,
  .widget-wrapper.rojo:not([class*="-bg"]) [class*='note'] .info-wrapper .title:hover,
  .widget-wrapper.rojo:not([class*="-bg"]) [class*='note'] .info-wrapper .author:hover,
  .widget-wrapper.rojo:not([class*="-bg"]) [class*='note'] .info-wrapper .article-category-grouper:hover,
  .widget-wrapper.rojo:not([class*="-bg"]) [class*='note'] .info-wrapper .article-kicker:hover {
    color: #D32F2F !important; }
  .widget-wrapper.rojo:not([class*="-bg"]) [class*='note'] .preview:hover,
  .widget-wrapper.rojo:not([class*="-bg"]) [class*='note'] .title:hover,
  .widget-wrapper.rojo:not([class*="-bg"]) [class*='note'] .author:hover {
    color: #D32F2F !important; }
  .widget-wrapper.rojo:not([class*="-bg"]) [data-type*="article"] i[class*="play"]:hover {
    color: #D32F2F !important; }

/* Widges con colores de fondo */
.marron-bg {
  background-color: #795548; }
  .marron-bg .highlighted-note-square-image .preview,
  .marron-bg .highlighted-note-square-image .title,
  .marron-bg .highlighted-note-square-image .author,
  .marron-bg .highlighted-note-square-image .article-category-grouper,
  .marron-bg .highlighted-note-square-image .article-kicker,
  .marron-bg .simple-note .preview,
  .marron-bg .simple-note .title,
  .marron-bg .simple-note .author,
  .marron-bg .simple-note .article-category-grouper,
  .marron-bg .simple-note .article-kicker,
  .marron-bg .simple-note-square-image .preview,
  .marron-bg .simple-note-square-image .title,
  .marron-bg .simple-note-square-image .author,
  .marron-bg .simple-note-square-image .article-category-grouper,
  .marron-bg .simple-note-square-image .article-kicker {
    color: #FFF !important; }
  .marron-bg .headline {
    margin-top: 0;
    padding-top: 8px; }
    .marron-bg .headline .obj-title {
      color: #FFF !important; }
  .marron-bg.negro {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .marron-bg.negro [class*='note'] .article-theme {
      background-color: rgba(26, 26, 29, 0.8) !important;
      color: #FFF !important; }
    .marron-bg.negro [data-type*="article"] i[class*="play"]:hover {
      color: #1A1A1D !important; }
  .marron-bg.rojo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .marron-bg.rojo [class*='note'] .article-theme {
      background-color: rgba(211, 47, 47, 0.8) !important;
      color: #FFF !important; }
    .marron-bg.rojo [data-type*="article"] i[class*="play"]:hover {
      color: #D32F2F !important; }
  .marron-bg.marron {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .marron-bg.marron [class*='note'] .article-theme {
      background-color: rgba(121, 85, 72, 0.8) !important;
      color: #FFF !important; }
    .marron-bg.marron [data-type*="article"] i[class*="play"]:hover {
      color: #795548 !important; }
  .marron-bg.verde {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .marron-bg.verde [class*='note'] .article-theme {
      background-color: rgba(136, 178, 68, 0.8) !important;
      color: #FFF !important; }
    .marron-bg.verde [data-type*="article"] i[class*="play"]:hover {
      color: #88B244 !important; }
  .marron-bg.ocre {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .marron-bg.ocre [class*='note'] .article-theme {
      background-color: rgba(237, 217, 192, 0.8) !important;
      color: #000 !important; }
    .marron-bg.ocre [data-type*="article"] i[class*="play"]:hover {
      color: #EDD9C0 !important; }
  .marron-bg.gris {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .marron-bg.gris [class*='note'] .article-theme {
      background-color: rgba(158, 158, 158, 0.8) !important;
      color: #FFF !important; }
    .marron-bg.gris [data-type*="article"] i[class*="play"]:hover {
      color: #9E9E9E !important; }
  .marron-bg.bordo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .marron-bg.bordo [class*='note'] .article-theme {
      background-color: rgba(118, 50, 63, 0.8) !important;
      color: #FFF !important; }
    .marron-bg.bordo [data-type*="article"] i[class*="play"]:hover {
      color: #76323F !important; }
  .marron-bg.azul {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .marron-bg.azul [class*='note'] .article-theme {
      background-color: rgba(48, 63, 159, 0.8) !important;
      color: #FFF !important; }
    .marron-bg.azul [data-type*="article"] i[class*="play"]:hover {
      color: #303F9F !important; }
  .marron-bg.celeste {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .marron-bg.celeste [class*='note'] .article-theme {
      background-color: rgba(3, 169, 244, 0.8) !important;
      color: #FFF !important; }
    .marron-bg.celeste [data-type*="article"] i[class*="play"]:hover {
      color: #03A9F4 !important; }
  .marron-bg.amarillo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .marron-bg.amarillo [class*='note'] .article-theme {
      background-color: rgba(247, 183, 51, 0.8) !important;
      color: #FFF !important; }
    .marron-bg.amarillo [data-type*="article"] i[class*="play"]:hover {
      color: #F7B733 !important; }
  .marron-bg.violeta {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .marron-bg.violeta [class*='note'] .article-theme {
      background-color: rgba(103, 58, 183, 0.8) !important;
      color: #FFF !important; }
    .marron-bg.violeta [data-type*="article"] i[class*="play"]:hover {
      color: #673AB7 !important; }
  .marron-bg.texto-blanco .article-shouts .info-wrapper .preview,
  .marron-bg.texto-blanco .article-shouts .info-wrapper .title,
  .marron-bg.texto-blanco .article-shouts .info-wrapper .author,
  .marron-bg.texto-blanco .article-shouts .info-wrapper .article-kicker,
  .marron-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview,
  .marron-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title,
  .marron-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author,
  .marron-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker,
  .marron-bg.texto-blanco .simple-list .info-wrapper .preview,
  .marron-bg.texto-blanco .simple-list .info-wrapper .title,
  .marron-bg.texto-blanco .simple-list .info-wrapper .author,
  .marron-bg.texto-blanco .simple-list .info-wrapper .article-kicker,
  .marron-bg.texto-blanco .simple-list-square-image .info-wrapper .preview,
  .marron-bg.texto-blanco .simple-list-square-image .info-wrapper .title,
  .marron-bg.texto-blanco .simple-list-square-image .info-wrapper .author,
  .marron-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker {
    color: #FFF !important; }
    .marron-bg.texto-blanco .article-shouts .info-wrapper .preview:hover,
    .marron-bg.texto-blanco .article-shouts .info-wrapper .title:hover,
    .marron-bg.texto-blanco .article-shouts .info-wrapper .author:hover,
    .marron-bg.texto-blanco .article-shouts .info-wrapper .article-kicker:hover,
    .marron-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview:hover,
    .marron-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title:hover,
    .marron-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author:hover,
    .marron-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker:hover,
    .marron-bg.texto-blanco .simple-list .info-wrapper .preview:hover,
    .marron-bg.texto-blanco .simple-list .info-wrapper .title:hover,
    .marron-bg.texto-blanco .simple-list .info-wrapper .author:hover,
    .marron-bg.texto-blanco .simple-list .info-wrapper .article-kicker:hover,
    .marron-bg.texto-blanco .simple-list-square-image .info-wrapper .preview:hover,
    .marron-bg.texto-blanco .simple-list-square-image .info-wrapper .title:hover,
    .marron-bg.texto-blanco .simple-list-square-image .info-wrapper .author:hover,
    .marron-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker:hover {
      color: #FFF !important; }
  .marron-bg.texto-blanco .article-shouts .preview,
  .marron-bg.texto-blanco .article-shouts .title,
  .marron-bg.texto-blanco .article-shouts .author,
  .marron-bg.texto-blanco .highlighted-note-square-image .preview,
  .marron-bg.texto-blanco .highlighted-note-square-image .title,
  .marron-bg.texto-blanco .highlighted-note-square-image .author,
  .marron-bg.texto-blanco .simple-list .preview,
  .marron-bg.texto-blanco .simple-list .title,
  .marron-bg.texto-blanco .simple-list .author,
  .marron-bg.texto-blanco .simple-list-square-image .preview,
  .marron-bg.texto-blanco .simple-list-square-image .title,
  .marron-bg.texto-blanco .simple-list-square-image .author {
    color: #FFF !important; }
    .marron-bg.texto-blanco .article-shouts .preview:hover,
    .marron-bg.texto-blanco .article-shouts .title:hover,
    .marron-bg.texto-blanco .article-shouts .author:hover,
    .marron-bg.texto-blanco .highlighted-note-square-image .preview:hover,
    .marron-bg.texto-blanco .highlighted-note-square-image .title:hover,
    .marron-bg.texto-blanco .highlighted-note-square-image .author:hover,
    .marron-bg.texto-blanco .simple-list .preview:hover,
    .marron-bg.texto-blanco .simple-list .title:hover,
    .marron-bg.texto-blanco .simple-list .author:hover,
    .marron-bg.texto-blanco .simple-list-square-image .preview:hover,
    .marron-bg.texto-blanco .simple-list-square-image .title:hover,
    .marron-bg.texto-blanco .simple-list-square-image .author:hover {
      color: #FFF !important; }
  .marron-bg.texto-blanco .standard-header {
    border-bottom-color: #FFF !important; }
    .marron-bg.texto-blanco .standard-header__title {
      color: #FFF !important; }
  .marron-bg.texto-blanco [class*='note'] .article-theme {
    color: #FFF !important; }
  .marron-bg.texto-blanco .headline {
    margin-top: 0;
    padding-top: 8px; }
    .marron-bg.texto-blanco .headline .obj-title {
      color: #FFF !important; }

/* Widgets con color relacionado */

.widget-wrapper.marron:not([class*="-bg"]) {
  /* Cabezales */
  /* Chapitas en los listados de notas */
  /* Icono de Play si la nota tiene video */ }
  .widget-wrapper.marron:not([class*="-bg"]) .headline .obj-title {
    color: #795548; }
  .widget-wrapper.marron:not([class*="-bg"]) [class*='note']:not(.highlighted-portrait-note):not(.highlighted-note-overprinted-title) .article-kicker {
    color: #795548 !important; }
  .widget-wrapper.marron:not([class*="-bg"]) [class*='note'] .article-theme {
    background-color: rgba(121, 85, 72, 0.8) !important;
    color: #FFF !important; }
  .widget-wrapper.marron:not([class*="-bg"]) [class*='note'] .info-wrapper .preview:hover,
  .widget-wrapper.marron:not([class*="-bg"]) [class*='note'] .info-wrapper .title:hover,
  .widget-wrapper.marron:not([class*="-bg"]) [class*='note'] .info-wrapper .author:hover,
  .widget-wrapper.marron:not([class*="-bg"]) [class*='note'] .info-wrapper .article-category-grouper:hover,
  .widget-wrapper.marron:not([class*="-bg"]) [class*='note'] .info-wrapper .article-kicker:hover {
    color: #795548 !important; }
  .widget-wrapper.marron:not([class*="-bg"]) [class*='note'] .preview:hover,
  .widget-wrapper.marron:not([class*="-bg"]) [class*='note'] .title:hover,
  .widget-wrapper.marron:not([class*="-bg"]) [class*='note'] .author:hover {
    color: #795548 !important; }
  .widget-wrapper.marron:not([class*="-bg"]) [data-type*="article"] i[class*="play"]:hover {
    color: #795548 !important; }

/* Widges con colores de fondo */
.verde-bg {
  background-color: #88B244; }
  .verde-bg .highlighted-note-square-image .preview,
  .verde-bg .highlighted-note-square-image .title,
  .verde-bg .highlighted-note-square-image .author,
  .verde-bg .highlighted-note-square-image .article-category-grouper,
  .verde-bg .highlighted-note-square-image .article-kicker,
  .verde-bg .simple-note .preview,
  .verde-bg .simple-note .title,
  .verde-bg .simple-note .author,
  .verde-bg .simple-note .article-category-grouper,
  .verde-bg .simple-note .article-kicker,
  .verde-bg .simple-note-square-image .preview,
  .verde-bg .simple-note-square-image .title,
  .verde-bg .simple-note-square-image .author,
  .verde-bg .simple-note-square-image .article-category-grouper,
  .verde-bg .simple-note-square-image .article-kicker {
    color: #FFF !important; }
  .verde-bg .headline {
    margin-top: 0;
    padding-top: 8px; }
    .verde-bg .headline .obj-title {
      color: #FFF !important; }
  .verde-bg.negro {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .verde-bg.negro [class*='note'] .article-theme {
      background-color: rgba(26, 26, 29, 0.8) !important;
      color: #FFF !important; }
    .verde-bg.negro [data-type*="article"] i[class*="play"]:hover {
      color: #1A1A1D !important; }
  .verde-bg.rojo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .verde-bg.rojo [class*='note'] .article-theme {
      background-color: rgba(211, 47, 47, 0.8) !important;
      color: #FFF !important; }
    .verde-bg.rojo [data-type*="article"] i[class*="play"]:hover {
      color: #D32F2F !important; }
  .verde-bg.marron {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .verde-bg.marron [class*='note'] .article-theme {
      background-color: rgba(121, 85, 72, 0.8) !important;
      color: #FFF !important; }
    .verde-bg.marron [data-type*="article"] i[class*="play"]:hover {
      color: #795548 !important; }
  .verde-bg.verde {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .verde-bg.verde [class*='note'] .article-theme {
      background-color: rgba(136, 178, 68, 0.8) !important;
      color: #FFF !important; }
    .verde-bg.verde [data-type*="article"] i[class*="play"]:hover {
      color: #88B244 !important; }
  .verde-bg.ocre {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .verde-bg.ocre [class*='note'] .article-theme {
      background-color: rgba(237, 217, 192, 0.8) !important;
      color: #000 !important; }
    .verde-bg.ocre [data-type*="article"] i[class*="play"]:hover {
      color: #EDD9C0 !important; }
  .verde-bg.gris {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .verde-bg.gris [class*='note'] .article-theme {
      background-color: rgba(158, 158, 158, 0.8) !important;
      color: #FFF !important; }
    .verde-bg.gris [data-type*="article"] i[class*="play"]:hover {
      color: #9E9E9E !important; }
  .verde-bg.bordo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .verde-bg.bordo [class*='note'] .article-theme {
      background-color: rgba(118, 50, 63, 0.8) !important;
      color: #FFF !important; }
    .verde-bg.bordo [data-type*="article"] i[class*="play"]:hover {
      color: #76323F !important; }
  .verde-bg.azul {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .verde-bg.azul [class*='note'] .article-theme {
      background-color: rgba(48, 63, 159, 0.8) !important;
      color: #FFF !important; }
    .verde-bg.azul [data-type*="article"] i[class*="play"]:hover {
      color: #303F9F !important; }
  .verde-bg.celeste {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .verde-bg.celeste [class*='note'] .article-theme {
      background-color: rgba(3, 169, 244, 0.8) !important;
      color: #FFF !important; }
    .verde-bg.celeste [data-type*="article"] i[class*="play"]:hover {
      color: #03A9F4 !important; }
  .verde-bg.amarillo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .verde-bg.amarillo [class*='note'] .article-theme {
      background-color: rgba(247, 183, 51, 0.8) !important;
      color: #FFF !important; }
    .verde-bg.amarillo [data-type*="article"] i[class*="play"]:hover {
      color: #F7B733 !important; }
  .verde-bg.violeta {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .verde-bg.violeta [class*='note'] .article-theme {
      background-color: rgba(103, 58, 183, 0.8) !important;
      color: #FFF !important; }
    .verde-bg.violeta [data-type*="article"] i[class*="play"]:hover {
      color: #673AB7 !important; }
  .verde-bg.texto-blanco .article-shouts .info-wrapper .preview,
  .verde-bg.texto-blanco .article-shouts .info-wrapper .title,
  .verde-bg.texto-blanco .article-shouts .info-wrapper .author,
  .verde-bg.texto-blanco .article-shouts .info-wrapper .article-kicker,
  .verde-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview,
  .verde-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title,
  .verde-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author,
  .verde-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker,
  .verde-bg.texto-blanco .simple-list .info-wrapper .preview,
  .verde-bg.texto-blanco .simple-list .info-wrapper .title,
  .verde-bg.texto-blanco .simple-list .info-wrapper .author,
  .verde-bg.texto-blanco .simple-list .info-wrapper .article-kicker,
  .verde-bg.texto-blanco .simple-list-square-image .info-wrapper .preview,
  .verde-bg.texto-blanco .simple-list-square-image .info-wrapper .title,
  .verde-bg.texto-blanco .simple-list-square-image .info-wrapper .author,
  .verde-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker {
    color: #FFF !important; }
    .verde-bg.texto-blanco .article-shouts .info-wrapper .preview:hover,
    .verde-bg.texto-blanco .article-shouts .info-wrapper .title:hover,
    .verde-bg.texto-blanco .article-shouts .info-wrapper .author:hover,
    .verde-bg.texto-blanco .article-shouts .info-wrapper .article-kicker:hover,
    .verde-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview:hover,
    .verde-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title:hover,
    .verde-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author:hover,
    .verde-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker:hover,
    .verde-bg.texto-blanco .simple-list .info-wrapper .preview:hover,
    .verde-bg.texto-blanco .simple-list .info-wrapper .title:hover,
    .verde-bg.texto-blanco .simple-list .info-wrapper .author:hover,
    .verde-bg.texto-blanco .simple-list .info-wrapper .article-kicker:hover,
    .verde-bg.texto-blanco .simple-list-square-image .info-wrapper .preview:hover,
    .verde-bg.texto-blanco .simple-list-square-image .info-wrapper .title:hover,
    .verde-bg.texto-blanco .simple-list-square-image .info-wrapper .author:hover,
    .verde-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker:hover {
      color: #FFF !important; }
  .verde-bg.texto-blanco .article-shouts .preview,
  .verde-bg.texto-blanco .article-shouts .title,
  .verde-bg.texto-blanco .article-shouts .author,
  .verde-bg.texto-blanco .highlighted-note-square-image .preview,
  .verde-bg.texto-blanco .highlighted-note-square-image .title,
  .verde-bg.texto-blanco .highlighted-note-square-image .author,
  .verde-bg.texto-blanco .simple-list .preview,
  .verde-bg.texto-blanco .simple-list .title,
  .verde-bg.texto-blanco .simple-list .author,
  .verde-bg.texto-blanco .simple-list-square-image .preview,
  .verde-bg.texto-blanco .simple-list-square-image .title,
  .verde-bg.texto-blanco .simple-list-square-image .author {
    color: #FFF !important; }
    .verde-bg.texto-blanco .article-shouts .preview:hover,
    .verde-bg.texto-blanco .article-shouts .title:hover,
    .verde-bg.texto-blanco .article-shouts .author:hover,
    .verde-bg.texto-blanco .highlighted-note-square-image .preview:hover,
    .verde-bg.texto-blanco .highlighted-note-square-image .title:hover,
    .verde-bg.texto-blanco .highlighted-note-square-image .author:hover,
    .verde-bg.texto-blanco .simple-list .preview:hover,
    .verde-bg.texto-blanco .simple-list .title:hover,
    .verde-bg.texto-blanco .simple-list .author:hover,
    .verde-bg.texto-blanco .simple-list-square-image .preview:hover,
    .verde-bg.texto-blanco .simple-list-square-image .title:hover,
    .verde-bg.texto-blanco .simple-list-square-image .author:hover {
      color: #FFF !important; }
  .verde-bg.texto-blanco .standard-header {
    border-bottom-color: #FFF !important; }
    .verde-bg.texto-blanco .standard-header__title {
      color: #FFF !important; }
  .verde-bg.texto-blanco [class*='note'] .article-theme {
    color: #FFF !important; }
  .verde-bg.texto-blanco .headline {
    margin-top: 0;
    padding-top: 8px; }
    .verde-bg.texto-blanco .headline .obj-title {
      color: #FFF !important; }

/* Widgets con color relacionado */

.widget-wrapper.verde:not([class*="-bg"]) {
  /* Cabezales */
  /* Chapitas en los listados de notas */
  /* Icono de Play si la nota tiene video */ }
  .widget-wrapper.verde:not([class*="-bg"]) .headline .obj-title {
    color: #88B244; }
  .widget-wrapper.verde:not([class*="-bg"]) [class*='note']:not(.highlighted-portrait-note):not(.highlighted-note-overprinted-title) .article-kicker {
    color: #88B244 !important; }
  .widget-wrapper.verde:not([class*="-bg"]) [class*='note'] .article-theme {
    background-color: rgba(136, 178, 68, 0.8) !important;
    color: #FFF !important; }
  .widget-wrapper.verde:not([class*="-bg"]) [class*='note'] .info-wrapper .preview:hover,
  .widget-wrapper.verde:not([class*="-bg"]) [class*='note'] .info-wrapper .title:hover,
  .widget-wrapper.verde:not([class*="-bg"]) [class*='note'] .info-wrapper .author:hover,
  .widget-wrapper.verde:not([class*="-bg"]) [class*='note'] .info-wrapper .article-category-grouper:hover,
  .widget-wrapper.verde:not([class*="-bg"]) [class*='note'] .info-wrapper .article-kicker:hover {
    color: #88B244 !important; }
  .widget-wrapper.verde:not([class*="-bg"]) [class*='note'] .preview:hover,
  .widget-wrapper.verde:not([class*="-bg"]) [class*='note'] .title:hover,
  .widget-wrapper.verde:not([class*="-bg"]) [class*='note'] .author:hover {
    color: #88B244 !important; }
  .widget-wrapper.verde:not([class*="-bg"]) [data-type*="article"] i[class*="play"]:hover {
    color: #88B244 !important; }

/* Widges con colores de fondo */
.ocre-bg {
  background-color: #EDD9C0; }
  .ocre-bg .highlighted-note-square-image .preview,
  .ocre-bg .highlighted-note-square-image .title,
  .ocre-bg .highlighted-note-square-image .author,
  .ocre-bg .highlighted-note-square-image .article-category-grouper,
  .ocre-bg .highlighted-note-square-image .article-kicker,
  .ocre-bg .simple-note .preview,
  .ocre-bg .simple-note .title,
  .ocre-bg .simple-note .author,
  .ocre-bg .simple-note .article-category-grouper,
  .ocre-bg .simple-note .article-kicker,
  .ocre-bg .simple-note-square-image .preview,
  .ocre-bg .simple-note-square-image .title,
  .ocre-bg .simple-note-square-image .author,
  .ocre-bg .simple-note-square-image .article-category-grouper,
  .ocre-bg .simple-note-square-image .article-kicker {
    color: #000 !important; }
  .ocre-bg .headline {
    margin-top: 0;
    padding-top: 8px; }
    .ocre-bg .headline .obj-title {
      color: #000 !important; }
  .ocre-bg.negro {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .ocre-bg.negro [class*='note'] .article-theme {
      background-color: rgba(26, 26, 29, 0.8) !important;
      color: #FFF !important; }
    .ocre-bg.negro [data-type*="article"] i[class*="play"]:hover {
      color: #1A1A1D !important; }
  .ocre-bg.rojo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .ocre-bg.rojo [class*='note'] .article-theme {
      background-color: rgba(211, 47, 47, 0.8) !important;
      color: #FFF !important; }
    .ocre-bg.rojo [data-type*="article"] i[class*="play"]:hover {
      color: #D32F2F !important; }
  .ocre-bg.marron {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .ocre-bg.marron [class*='note'] .article-theme {
      background-color: rgba(121, 85, 72, 0.8) !important;
      color: #FFF !important; }
    .ocre-bg.marron [data-type*="article"] i[class*="play"]:hover {
      color: #795548 !important; }
  .ocre-bg.verde {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .ocre-bg.verde [class*='note'] .article-theme {
      background-color: rgba(136, 178, 68, 0.8) !important;
      color: #FFF !important; }
    .ocre-bg.verde [data-type*="article"] i[class*="play"]:hover {
      color: #88B244 !important; }
  .ocre-bg.ocre {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .ocre-bg.ocre [class*='note'] .article-theme {
      background-color: rgba(237, 217, 192, 0.8) !important;
      color: #000 !important; }
    .ocre-bg.ocre [data-type*="article"] i[class*="play"]:hover {
      color: #EDD9C0 !important; }
  .ocre-bg.gris {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .ocre-bg.gris [class*='note'] .article-theme {
      background-color: rgba(158, 158, 158, 0.8) !important;
      color: #FFF !important; }
    .ocre-bg.gris [data-type*="article"] i[class*="play"]:hover {
      color: #9E9E9E !important; }
  .ocre-bg.bordo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .ocre-bg.bordo [class*='note'] .article-theme {
      background-color: rgba(118, 50, 63, 0.8) !important;
      color: #FFF !important; }
    .ocre-bg.bordo [data-type*="article"] i[class*="play"]:hover {
      color: #76323F !important; }
  .ocre-bg.azul {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .ocre-bg.azul [class*='note'] .article-theme {
      background-color: rgba(48, 63, 159, 0.8) !important;
      color: #FFF !important; }
    .ocre-bg.azul [data-type*="article"] i[class*="play"]:hover {
      color: #303F9F !important; }
  .ocre-bg.celeste {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .ocre-bg.celeste [class*='note'] .article-theme {
      background-color: rgba(3, 169, 244, 0.8) !important;
      color: #FFF !important; }
    .ocre-bg.celeste [data-type*="article"] i[class*="play"]:hover {
      color: #03A9F4 !important; }
  .ocre-bg.amarillo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .ocre-bg.amarillo [class*='note'] .article-theme {
      background-color: rgba(247, 183, 51, 0.8) !important;
      color: #FFF !important; }
    .ocre-bg.amarillo [data-type*="article"] i[class*="play"]:hover {
      color: #F7B733 !important; }
  .ocre-bg.violeta {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .ocre-bg.violeta [class*='note'] .article-theme {
      background-color: rgba(103, 58, 183, 0.8) !important;
      color: #FFF !important; }
    .ocre-bg.violeta [data-type*="article"] i[class*="play"]:hover {
      color: #673AB7 !important; }
  .ocre-bg.texto-blanco .article-shouts .info-wrapper .preview,
  .ocre-bg.texto-blanco .article-shouts .info-wrapper .title,
  .ocre-bg.texto-blanco .article-shouts .info-wrapper .author,
  .ocre-bg.texto-blanco .article-shouts .info-wrapper .article-kicker,
  .ocre-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview,
  .ocre-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title,
  .ocre-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author,
  .ocre-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker,
  .ocre-bg.texto-blanco .simple-list .info-wrapper .preview,
  .ocre-bg.texto-blanco .simple-list .info-wrapper .title,
  .ocre-bg.texto-blanco .simple-list .info-wrapper .author,
  .ocre-bg.texto-blanco .simple-list .info-wrapper .article-kicker,
  .ocre-bg.texto-blanco .simple-list-square-image .info-wrapper .preview,
  .ocre-bg.texto-blanco .simple-list-square-image .info-wrapper .title,
  .ocre-bg.texto-blanco .simple-list-square-image .info-wrapper .author,
  .ocre-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker {
    color: #FFF !important; }
    .ocre-bg.texto-blanco .article-shouts .info-wrapper .preview:hover,
    .ocre-bg.texto-blanco .article-shouts .info-wrapper .title:hover,
    .ocre-bg.texto-blanco .article-shouts .info-wrapper .author:hover,
    .ocre-bg.texto-blanco .article-shouts .info-wrapper .article-kicker:hover,
    .ocre-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview:hover,
    .ocre-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title:hover,
    .ocre-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author:hover,
    .ocre-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker:hover,
    .ocre-bg.texto-blanco .simple-list .info-wrapper .preview:hover,
    .ocre-bg.texto-blanco .simple-list .info-wrapper .title:hover,
    .ocre-bg.texto-blanco .simple-list .info-wrapper .author:hover,
    .ocre-bg.texto-blanco .simple-list .info-wrapper .article-kicker:hover,
    .ocre-bg.texto-blanco .simple-list-square-image .info-wrapper .preview:hover,
    .ocre-bg.texto-blanco .simple-list-square-image .info-wrapper .title:hover,
    .ocre-bg.texto-blanco .simple-list-square-image .info-wrapper .author:hover,
    .ocre-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker:hover {
      color: #FFF !important; }
  .ocre-bg.texto-blanco .article-shouts .preview,
  .ocre-bg.texto-blanco .article-shouts .title,
  .ocre-bg.texto-blanco .article-shouts .author,
  .ocre-bg.texto-blanco .highlighted-note-square-image .preview,
  .ocre-bg.texto-blanco .highlighted-note-square-image .title,
  .ocre-bg.texto-blanco .highlighted-note-square-image .author,
  .ocre-bg.texto-blanco .simple-list .preview,
  .ocre-bg.texto-blanco .simple-list .title,
  .ocre-bg.texto-blanco .simple-list .author,
  .ocre-bg.texto-blanco .simple-list-square-image .preview,
  .ocre-bg.texto-blanco .simple-list-square-image .title,
  .ocre-bg.texto-blanco .simple-list-square-image .author {
    color: #FFF !important; }
    .ocre-bg.texto-blanco .article-shouts .preview:hover,
    .ocre-bg.texto-blanco .article-shouts .title:hover,
    .ocre-bg.texto-blanco .article-shouts .author:hover,
    .ocre-bg.texto-blanco .highlighted-note-square-image .preview:hover,
    .ocre-bg.texto-blanco .highlighted-note-square-image .title:hover,
    .ocre-bg.texto-blanco .highlighted-note-square-image .author:hover,
    .ocre-bg.texto-blanco .simple-list .preview:hover,
    .ocre-bg.texto-blanco .simple-list .title:hover,
    .ocre-bg.texto-blanco .simple-list .author:hover,
    .ocre-bg.texto-blanco .simple-list-square-image .preview:hover,
    .ocre-bg.texto-blanco .simple-list-square-image .title:hover,
    .ocre-bg.texto-blanco .simple-list-square-image .author:hover {
      color: #FFF !important; }
  .ocre-bg.texto-blanco .standard-header {
    border-bottom-color: #FFF !important; }
    .ocre-bg.texto-blanco .standard-header__title {
      color: #FFF !important; }
  .ocre-bg.texto-blanco [class*='note'] .article-theme {
    color: #FFF !important; }
  .ocre-bg.texto-blanco .headline {
    margin-top: 0;
    padding-top: 8px; }
    .ocre-bg.texto-blanco .headline .obj-title {
      color: #FFF !important; }

/* Widgets con color relacionado */

.widget-wrapper.ocre:not([class*="-bg"]) {
  /* Cabezales */
  /* Chapitas en los listados de notas */
  /* Icono de Play si la nota tiene video */ }
  .widget-wrapper.ocre:not([class*="-bg"]) .headline .obj-title {
    color: #EDD9C0; }
  .widget-wrapper.ocre:not([class*="-bg"]) [class*='note']:not(.highlighted-portrait-note):not(.highlighted-note-overprinted-title) .article-kicker {
    color: #EDD9C0 !important; }
  .widget-wrapper.ocre:not([class*="-bg"]) [class*='note'] .article-theme {
    background-color: rgba(237, 217, 192, 0.8) !important;
    color: #000 !important; }
  .widget-wrapper.ocre:not([class*="-bg"]) [class*='note'] .info-wrapper .preview:hover,
  .widget-wrapper.ocre:not([class*="-bg"]) [class*='note'] .info-wrapper .title:hover,
  .widget-wrapper.ocre:not([class*="-bg"]) [class*='note'] .info-wrapper .author:hover,
  .widget-wrapper.ocre:not([class*="-bg"]) [class*='note'] .info-wrapper .article-category-grouper:hover,
  .widget-wrapper.ocre:not([class*="-bg"]) [class*='note'] .info-wrapper .article-kicker:hover {
    color: #EDD9C0 !important; }
  .widget-wrapper.ocre:not([class*="-bg"]) [class*='note'] .preview:hover,
  .widget-wrapper.ocre:not([class*="-bg"]) [class*='note'] .title:hover,
  .widget-wrapper.ocre:not([class*="-bg"]) [class*='note'] .author:hover {
    color: #EDD9C0 !important; }
  .widget-wrapper.ocre:not([class*="-bg"]) [data-type*="article"] i[class*="play"]:hover {
    color: #EDD9C0 !important; }
  .widget-wrapper.texto-blanco .free-text .body-content {
    color: #000; }

/* Widges con colores de fondo */
.gris-bg {
  background-color: #9E9E9E; }
  .gris-bg .highlighted-note-square-image .preview,
  .gris-bg .highlighted-note-square-image .title,
  .gris-bg .highlighted-note-square-image .author,
  .gris-bg .highlighted-note-square-image .article-category-grouper,
  .gris-bg .highlighted-note-square-image .article-kicker,
  .gris-bg .simple-note .preview,
  .gris-bg .simple-note .title,
  .gris-bg .simple-note .author,
  .gris-bg .simple-note .article-category-grouper,
  .gris-bg .simple-note .article-kicker,
  .gris-bg .simple-note-square-image .preview,
  .gris-bg .simple-note-square-image .title,
  .gris-bg .simple-note-square-image .author,
  .gris-bg .simple-note-square-image .article-category-grouper,
  .gris-bg .simple-note-square-image .article-kicker {
    color: #FFF !important; }
  .gris-bg .headline {
    margin-top: 0;
    padding-top: 8px; }
    .gris-bg .headline .obj-title {
      color: #FFF !important; }
  .gris-bg.negro {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .gris-bg.negro [class*='note'] .article-theme {
      background-color: rgba(26, 26, 29, 0.8) !important;
      color: #FFF !important; }
    .gris-bg.negro [data-type*="article"] i[class*="play"]:hover {
      color: #1A1A1D !important; }
  .gris-bg.rojo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .gris-bg.rojo [class*='note'] .article-theme {
      background-color: rgba(211, 47, 47, 0.8) !important;
      color: #FFF !important; }
    .gris-bg.rojo [data-type*="article"] i[class*="play"]:hover {
      color: #D32F2F !important; }
  .gris-bg.marron {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .gris-bg.marron [class*='note'] .article-theme {
      background-color: rgba(121, 85, 72, 0.8) !important;
      color: #FFF !important; }
    .gris-bg.marron [data-type*="article"] i[class*="play"]:hover {
      color: #795548 !important; }
  .gris-bg.verde {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .gris-bg.verde [class*='note'] .article-theme {
      background-color: rgba(136, 178, 68, 0.8) !important;
      color: #FFF !important; }
    .gris-bg.verde [data-type*="article"] i[class*="play"]:hover {
      color: #88B244 !important; }
  .gris-bg.ocre {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .gris-bg.ocre [class*='note'] .article-theme {
      background-color: rgba(237, 217, 192, 0.8) !important;
      color: #000 !important; }
    .gris-bg.ocre [data-type*="article"] i[class*="play"]:hover {
      color: #EDD9C0 !important; }
  .gris-bg.gris {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .gris-bg.gris [class*='note'] .article-theme {
      background-color: rgba(158, 158, 158, 0.8) !important;
      color: #FFF !important; }
    .gris-bg.gris [data-type*="article"] i[class*="play"]:hover {
      color: #9E9E9E !important; }
  .gris-bg.bordo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .gris-bg.bordo [class*='note'] .article-theme {
      background-color: rgba(118, 50, 63, 0.8) !important;
      color: #FFF !important; }
    .gris-bg.bordo [data-type*="article"] i[class*="play"]:hover {
      color: #76323F !important; }
  .gris-bg.azul {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .gris-bg.azul [class*='note'] .article-theme {
      background-color: rgba(48, 63, 159, 0.8) !important;
      color: #FFF !important; }
    .gris-bg.azul [data-type*="article"] i[class*="play"]:hover {
      color: #303F9F !important; }
  .gris-bg.celeste {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .gris-bg.celeste [class*='note'] .article-theme {
      background-color: rgba(3, 169, 244, 0.8) !important;
      color: #FFF !important; }
    .gris-bg.celeste [data-type*="article"] i[class*="play"]:hover {
      color: #03A9F4 !important; }
  .gris-bg.amarillo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .gris-bg.amarillo [class*='note'] .article-theme {
      background-color: rgba(247, 183, 51, 0.8) !important;
      color: #FFF !important; }
    .gris-bg.amarillo [data-type*="article"] i[class*="play"]:hover {
      color: #F7B733 !important; }
  .gris-bg.violeta {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .gris-bg.violeta [class*='note'] .article-theme {
      background-color: rgba(103, 58, 183, 0.8) !important;
      color: #FFF !important; }
    .gris-bg.violeta [data-type*="article"] i[class*="play"]:hover {
      color: #673AB7 !important; }
  .gris-bg.texto-blanco .article-shouts .info-wrapper .preview,
  .gris-bg.texto-blanco .article-shouts .info-wrapper .title,
  .gris-bg.texto-blanco .article-shouts .info-wrapper .author,
  .gris-bg.texto-blanco .article-shouts .info-wrapper .article-kicker,
  .gris-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview,
  .gris-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title,
  .gris-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author,
  .gris-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker,
  .gris-bg.texto-blanco .simple-list .info-wrapper .preview,
  .gris-bg.texto-blanco .simple-list .info-wrapper .title,
  .gris-bg.texto-blanco .simple-list .info-wrapper .author,
  .gris-bg.texto-blanco .simple-list .info-wrapper .article-kicker,
  .gris-bg.texto-blanco .simple-list-square-image .info-wrapper .preview,
  .gris-bg.texto-blanco .simple-list-square-image .info-wrapper .title,
  .gris-bg.texto-blanco .simple-list-square-image .info-wrapper .author,
  .gris-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker {
    color: #FFF !important; }
    .gris-bg.texto-blanco .article-shouts .info-wrapper .preview:hover,
    .gris-bg.texto-blanco .article-shouts .info-wrapper .title:hover,
    .gris-bg.texto-blanco .article-shouts .info-wrapper .author:hover,
    .gris-bg.texto-blanco .article-shouts .info-wrapper .article-kicker:hover,
    .gris-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview:hover,
    .gris-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title:hover,
    .gris-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author:hover,
    .gris-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker:hover,
    .gris-bg.texto-blanco .simple-list .info-wrapper .preview:hover,
    .gris-bg.texto-blanco .simple-list .info-wrapper .title:hover,
    .gris-bg.texto-blanco .simple-list .info-wrapper .author:hover,
    .gris-bg.texto-blanco .simple-list .info-wrapper .article-kicker:hover,
    .gris-bg.texto-blanco .simple-list-square-image .info-wrapper .preview:hover,
    .gris-bg.texto-blanco .simple-list-square-image .info-wrapper .title:hover,
    .gris-bg.texto-blanco .simple-list-square-image .info-wrapper .author:hover,
    .gris-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker:hover {
      color: #FFF !important; }
  .gris-bg.texto-blanco .article-shouts .preview,
  .gris-bg.texto-blanco .article-shouts .title,
  .gris-bg.texto-blanco .article-shouts .author,
  .gris-bg.texto-blanco .highlighted-note-square-image .preview,
  .gris-bg.texto-blanco .highlighted-note-square-image .title,
  .gris-bg.texto-blanco .highlighted-note-square-image .author,
  .gris-bg.texto-blanco .simple-list .preview,
  .gris-bg.texto-blanco .simple-list .title,
  .gris-bg.texto-blanco .simple-list .author,
  .gris-bg.texto-blanco .simple-list-square-image .preview,
  .gris-bg.texto-blanco .simple-list-square-image .title,
  .gris-bg.texto-blanco .simple-list-square-image .author {
    color: #FFF !important; }
    .gris-bg.texto-blanco .article-shouts .preview:hover,
    .gris-bg.texto-blanco .article-shouts .title:hover,
    .gris-bg.texto-blanco .article-shouts .author:hover,
    .gris-bg.texto-blanco .highlighted-note-square-image .preview:hover,
    .gris-bg.texto-blanco .highlighted-note-square-image .title:hover,
    .gris-bg.texto-blanco .highlighted-note-square-image .author:hover,
    .gris-bg.texto-blanco .simple-list .preview:hover,
    .gris-bg.texto-blanco .simple-list .title:hover,
    .gris-bg.texto-blanco .simple-list .author:hover,
    .gris-bg.texto-blanco .simple-list-square-image .preview:hover,
    .gris-bg.texto-blanco .simple-list-square-image .title:hover,
    .gris-bg.texto-blanco .simple-list-square-image .author:hover {
      color: #FFF !important; }
  .gris-bg.texto-blanco .standard-header {
    border-bottom-color: #FFF !important; }
    .gris-bg.texto-blanco .standard-header__title {
      color: #FFF !important; }
  .gris-bg.texto-blanco [class*='note'] .article-theme {
    color: #FFF !important; }
  .gris-bg.texto-blanco .headline {
    margin-top: 0;
    padding-top: 8px; }
    .gris-bg.texto-blanco .headline .obj-title {
      color: #FFF !important; }

/* Widgets con color relacionado */

.widget-wrapper.gris:not([class*="-bg"]) {
  /* Cabezales */
  /* Chapitas en los listados de notas */
  /* Icono de Play si la nota tiene video */ }
  .widget-wrapper.gris:not([class*="-bg"]) .headline .obj-title {
    color: #9E9E9E; }
  .widget-wrapper.gris:not([class*="-bg"]) [class*='note']:not(.highlighted-portrait-note):not(.highlighted-note-overprinted-title) .article-kicker {
    color: #9E9E9E !important; }
  .widget-wrapper.gris:not([class*="-bg"]) [class*='note'] .article-theme {
    background-color: rgba(158, 158, 158, 0.8) !important;
    color: #FFF !important; }
  .widget-wrapper.gris:not([class*="-bg"]) [class*='note'] .info-wrapper .preview:hover,
  .widget-wrapper.gris:not([class*="-bg"]) [class*='note'] .info-wrapper .title:hover,
  .widget-wrapper.gris:not([class*="-bg"]) [class*='note'] .info-wrapper .author:hover,
  .widget-wrapper.gris:not([class*="-bg"]) [class*='note'] .info-wrapper .article-category-grouper:hover,
  .widget-wrapper.gris:not([class*="-bg"]) [class*='note'] .info-wrapper .article-kicker:hover {
    color: #9E9E9E !important; }
  .widget-wrapper.gris:not([class*="-bg"]) [class*='note'] .preview:hover,
  .widget-wrapper.gris:not([class*="-bg"]) [class*='note'] .title:hover,
  .widget-wrapper.gris:not([class*="-bg"]) [class*='note'] .author:hover {
    color: #9E9E9E !important; }
  .widget-wrapper.gris:not([class*="-bg"]) [data-type*="article"] i[class*="play"]:hover {
    color: #9E9E9E !important; }

/* Widges con colores de fondo */
.bordo-bg {
  background-color: #76323F; }
  .bordo-bg .highlighted-note-square-image .preview,
  .bordo-bg .highlighted-note-square-image .title,
  .bordo-bg .highlighted-note-square-image .author,
  .bordo-bg .highlighted-note-square-image .article-category-grouper,
  .bordo-bg .highlighted-note-square-image .article-kicker,
  .bordo-bg .simple-note .preview,
  .bordo-bg .simple-note .title,
  .bordo-bg .simple-note .author,
  .bordo-bg .simple-note .article-category-grouper,
  .bordo-bg .simple-note .article-kicker,
  .bordo-bg .simple-note-square-image .preview,
  .bordo-bg .simple-note-square-image .title,
  .bordo-bg .simple-note-square-image .author,
  .bordo-bg .simple-note-square-image .article-category-grouper,
  .bordo-bg .simple-note-square-image .article-kicker {
    color: #FFF !important; }
  .bordo-bg .headline {
    margin-top: 0;
    padding-top: 8px; }
    .bordo-bg .headline .obj-title {
      color: #FFF !important; }
  .bordo-bg.negro {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .bordo-bg.negro [class*='note'] .article-theme {
      background-color: rgba(26, 26, 29, 0.8) !important;
      color: #FFF !important; }
    .bordo-bg.negro [data-type*="article"] i[class*="play"]:hover {
      color: #1A1A1D !important; }
  .bordo-bg.rojo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .bordo-bg.rojo [class*='note'] .article-theme {
      background-color: rgba(211, 47, 47, 0.8) !important;
      color: #FFF !important; }
    .bordo-bg.rojo [data-type*="article"] i[class*="play"]:hover {
      color: #D32F2F !important; }
  .bordo-bg.marron {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .bordo-bg.marron [class*='note'] .article-theme {
      background-color: rgba(121, 85, 72, 0.8) !important;
      color: #FFF !important; }
    .bordo-bg.marron [data-type*="article"] i[class*="play"]:hover {
      color: #795548 !important; }
  .bordo-bg.verde {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .bordo-bg.verde [class*='note'] .article-theme {
      background-color: rgba(136, 178, 68, 0.8) !important;
      color: #FFF !important; }
    .bordo-bg.verde [data-type*="article"] i[class*="play"]:hover {
      color: #88B244 !important; }
  .bordo-bg.ocre {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .bordo-bg.ocre [class*='note'] .article-theme {
      background-color: rgba(237, 217, 192, 0.8) !important;
      color: #000 !important; }
    .bordo-bg.ocre [data-type*="article"] i[class*="play"]:hover {
      color: #EDD9C0 !important; }
  .bordo-bg.gris {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .bordo-bg.gris [class*='note'] .article-theme {
      background-color: rgba(158, 158, 158, 0.8) !important;
      color: #FFF !important; }
    .bordo-bg.gris [data-type*="article"] i[class*="play"]:hover {
      color: #9E9E9E !important; }
  .bordo-bg.bordo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .bordo-bg.bordo [class*='note'] .article-theme {
      background-color: rgba(118, 50, 63, 0.8) !important;
      color: #FFF !important; }
    .bordo-bg.bordo [data-type*="article"] i[class*="play"]:hover {
      color: #76323F !important; }
  .bordo-bg.azul {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .bordo-bg.azul [class*='note'] .article-theme {
      background-color: rgba(48, 63, 159, 0.8) !important;
      color: #FFF !important; }
    .bordo-bg.azul [data-type*="article"] i[class*="play"]:hover {
      color: #303F9F !important; }
  .bordo-bg.celeste {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .bordo-bg.celeste [class*='note'] .article-theme {
      background-color: rgba(3, 169, 244, 0.8) !important;
      color: #FFF !important; }
    .bordo-bg.celeste [data-type*="article"] i[class*="play"]:hover {
      color: #03A9F4 !important; }
  .bordo-bg.amarillo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .bordo-bg.amarillo [class*='note'] .article-theme {
      background-color: rgba(247, 183, 51, 0.8) !important;
      color: #FFF !important; }
    .bordo-bg.amarillo [data-type*="article"] i[class*="play"]:hover {
      color: #F7B733 !important; }
  .bordo-bg.violeta {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .bordo-bg.violeta [class*='note'] .article-theme {
      background-color: rgba(103, 58, 183, 0.8) !important;
      color: #FFF !important; }
    .bordo-bg.violeta [data-type*="article"] i[class*="play"]:hover {
      color: #673AB7 !important; }
  .bordo-bg.texto-blanco .article-shouts .info-wrapper .preview,
  .bordo-bg.texto-blanco .article-shouts .info-wrapper .title,
  .bordo-bg.texto-blanco .article-shouts .info-wrapper .author,
  .bordo-bg.texto-blanco .article-shouts .info-wrapper .article-kicker,
  .bordo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview,
  .bordo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title,
  .bordo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author,
  .bordo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker,
  .bordo-bg.texto-blanco .simple-list .info-wrapper .preview,
  .bordo-bg.texto-blanco .simple-list .info-wrapper .title,
  .bordo-bg.texto-blanco .simple-list .info-wrapper .author,
  .bordo-bg.texto-blanco .simple-list .info-wrapper .article-kicker,
  .bordo-bg.texto-blanco .simple-list-square-image .info-wrapper .preview,
  .bordo-bg.texto-blanco .simple-list-square-image .info-wrapper .title,
  .bordo-bg.texto-blanco .simple-list-square-image .info-wrapper .author,
  .bordo-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker {
    color: #FFF !important; }
    .bordo-bg.texto-blanco .article-shouts .info-wrapper .preview:hover,
    .bordo-bg.texto-blanco .article-shouts .info-wrapper .title:hover,
    .bordo-bg.texto-blanco .article-shouts .info-wrapper .author:hover,
    .bordo-bg.texto-blanco .article-shouts .info-wrapper .article-kicker:hover,
    .bordo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview:hover,
    .bordo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title:hover,
    .bordo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author:hover,
    .bordo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker:hover,
    .bordo-bg.texto-blanco .simple-list .info-wrapper .preview:hover,
    .bordo-bg.texto-blanco .simple-list .info-wrapper .title:hover,
    .bordo-bg.texto-blanco .simple-list .info-wrapper .author:hover,
    .bordo-bg.texto-blanco .simple-list .info-wrapper .article-kicker:hover,
    .bordo-bg.texto-blanco .simple-list-square-image .info-wrapper .preview:hover,
    .bordo-bg.texto-blanco .simple-list-square-image .info-wrapper .title:hover,
    .bordo-bg.texto-blanco .simple-list-square-image .info-wrapper .author:hover,
    .bordo-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker:hover {
      color: #FFF !important; }
  .bordo-bg.texto-blanco .article-shouts .preview,
  .bordo-bg.texto-blanco .article-shouts .title,
  .bordo-bg.texto-blanco .article-shouts .author,
  .bordo-bg.texto-blanco .highlighted-note-square-image .preview,
  .bordo-bg.texto-blanco .highlighted-note-square-image .title,
  .bordo-bg.texto-blanco .highlighted-note-square-image .author,
  .bordo-bg.texto-blanco .simple-list .preview,
  .bordo-bg.texto-blanco .simple-list .title,
  .bordo-bg.texto-blanco .simple-list .author,
  .bordo-bg.texto-blanco .simple-list-square-image .preview,
  .bordo-bg.texto-blanco .simple-list-square-image .title,
  .bordo-bg.texto-blanco .simple-list-square-image .author {
    color: #FFF !important; }
    .bordo-bg.texto-blanco .article-shouts .preview:hover,
    .bordo-bg.texto-blanco .article-shouts .title:hover,
    .bordo-bg.texto-blanco .article-shouts .author:hover,
    .bordo-bg.texto-blanco .highlighted-note-square-image .preview:hover,
    .bordo-bg.texto-blanco .highlighted-note-square-image .title:hover,
    .bordo-bg.texto-blanco .highlighted-note-square-image .author:hover,
    .bordo-bg.texto-blanco .simple-list .preview:hover,
    .bordo-bg.texto-blanco .simple-list .title:hover,
    .bordo-bg.texto-blanco .simple-list .author:hover,
    .bordo-bg.texto-blanco .simple-list-square-image .preview:hover,
    .bordo-bg.texto-blanco .simple-list-square-image .title:hover,
    .bordo-bg.texto-blanco .simple-list-square-image .author:hover {
      color: #FFF !important; }
  .bordo-bg.texto-blanco .standard-header {
    border-bottom-color: #FFF !important; }
    .bordo-bg.texto-blanco .standard-header__title {
      color: #FFF !important; }
  .bordo-bg.texto-blanco [class*='note'] .article-theme {
    color: #FFF !important; }
  .bordo-bg.texto-blanco .headline {
    margin-top: 0;
    padding-top: 8px; }
    .bordo-bg.texto-blanco .headline .obj-title {
      color: #FFF !important; }

/* Widgets con color relacionado */

.widget-wrapper.bordo:not([class*="-bg"]) {
  /* Cabezales */
  /* Chapitas en los listados de notas */
  /* Icono de Play si la nota tiene video */ }
  .widget-wrapper.bordo:not([class*="-bg"]) .headline .obj-title {
    color: #76323F; }
  .widget-wrapper.bordo:not([class*="-bg"]) [class*='note']:not(.highlighted-portrait-note):not(.highlighted-note-overprinted-title) .article-kicker {
    color: #76323F !important; }
  .widget-wrapper.bordo:not([class*="-bg"]) [class*='note'] .article-theme {
    background-color: rgba(118, 50, 63, 0.8) !important;
    color: #FFF !important; }
  .widget-wrapper.bordo:not([class*="-bg"]) [class*='note'] .info-wrapper .preview:hover,
  .widget-wrapper.bordo:not([class*="-bg"]) [class*='note'] .info-wrapper .title:hover,
  .widget-wrapper.bordo:not([class*="-bg"]) [class*='note'] .info-wrapper .author:hover,
  .widget-wrapper.bordo:not([class*="-bg"]) [class*='note'] .info-wrapper .article-category-grouper:hover,
  .widget-wrapper.bordo:not([class*="-bg"]) [class*='note'] .info-wrapper .article-kicker:hover {
    color: #76323F !important; }
  .widget-wrapper.bordo:not([class*="-bg"]) [class*='note'] .preview:hover,
  .widget-wrapper.bordo:not([class*="-bg"]) [class*='note'] .title:hover,
  .widget-wrapper.bordo:not([class*="-bg"]) [class*='note'] .author:hover {
    color: #76323F !important; }
  .widget-wrapper.bordo:not([class*="-bg"]) [data-type*="article"] i[class*="play"]:hover {
    color: #76323F !important; }

/* Widges con colores de fondo */
.azul-bg {
  background-color: #303F9F; }
  .azul-bg .highlighted-note-square-image .preview,
  .azul-bg .highlighted-note-square-image .title,
  .azul-bg .highlighted-note-square-image .author,
  .azul-bg .highlighted-note-square-image .article-category-grouper,
  .azul-bg .highlighted-note-square-image .article-kicker,
  .azul-bg .simple-note .preview,
  .azul-bg .simple-note .title,
  .azul-bg .simple-note .author,
  .azul-bg .simple-note .article-category-grouper,
  .azul-bg .simple-note .article-kicker,
  .azul-bg .simple-note-square-image .preview,
  .azul-bg .simple-note-square-image .title,
  .azul-bg .simple-note-square-image .author,
  .azul-bg .simple-note-square-image .article-category-grouper,
  .azul-bg .simple-note-square-image .article-kicker {
    color: #FFF !important; }
  .azul-bg .headline {
    margin-top: 0;
    padding-top: 8px; }
    .azul-bg .headline .obj-title {
      color: #FFF !important; }
  .azul-bg.negro {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .azul-bg.negro [class*='note'] .article-theme {
      background-color: rgba(26, 26, 29, 0.8) !important;
      color: #FFF !important; }
    .azul-bg.negro [data-type*="article"] i[class*="play"]:hover {
      color: #1A1A1D !important; }
  .azul-bg.rojo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .azul-bg.rojo [class*='note'] .article-theme {
      background-color: rgba(211, 47, 47, 0.8) !important;
      color: #FFF !important; }
    .azul-bg.rojo [data-type*="article"] i[class*="play"]:hover {
      color: #D32F2F !important; }
  .azul-bg.marron {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .azul-bg.marron [class*='note'] .article-theme {
      background-color: rgba(121, 85, 72, 0.8) !important;
      color: #FFF !important; }
    .azul-bg.marron [data-type*="article"] i[class*="play"]:hover {
      color: #795548 !important; }
  .azul-bg.verde {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .azul-bg.verde [class*='note'] .article-theme {
      background-color: rgba(136, 178, 68, 0.8) !important;
      color: #FFF !important; }
    .azul-bg.verde [data-type*="article"] i[class*="play"]:hover {
      color: #88B244 !important; }
  .azul-bg.ocre {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .azul-bg.ocre [class*='note'] .article-theme {
      background-color: rgba(237, 217, 192, 0.8) !important;
      color: #000 !important; }
    .azul-bg.ocre [data-type*="article"] i[class*="play"]:hover {
      color: #EDD9C0 !important; }
  .azul-bg.gris {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .azul-bg.gris [class*='note'] .article-theme {
      background-color: rgba(158, 158, 158, 0.8) !important;
      color: #FFF !important; }
    .azul-bg.gris [data-type*="article"] i[class*="play"]:hover {
      color: #9E9E9E !important; }
  .azul-bg.bordo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .azul-bg.bordo [class*='note'] .article-theme {
      background-color: rgba(118, 50, 63, 0.8) !important;
      color: #FFF !important; }
    .azul-bg.bordo [data-type*="article"] i[class*="play"]:hover {
      color: #76323F !important; }
  .azul-bg.azul {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .azul-bg.azul [class*='note'] .article-theme {
      background-color: rgba(48, 63, 159, 0.8) !important;
      color: #FFF !important; }
    .azul-bg.azul [data-type*="article"] i[class*="play"]:hover {
      color: #303F9F !important; }
  .azul-bg.celeste {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .azul-bg.celeste [class*='note'] .article-theme {
      background-color: rgba(3, 169, 244, 0.8) !important;
      color: #FFF !important; }
    .azul-bg.celeste [data-type*="article"] i[class*="play"]:hover {
      color: #03A9F4 !important; }
  .azul-bg.amarillo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .azul-bg.amarillo [class*='note'] .article-theme {
      background-color: rgba(247, 183, 51, 0.8) !important;
      color: #FFF !important; }
    .azul-bg.amarillo [data-type*="article"] i[class*="play"]:hover {
      color: #F7B733 !important; }
  .azul-bg.violeta {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .azul-bg.violeta [class*='note'] .article-theme {
      background-color: rgba(103, 58, 183, 0.8) !important;
      color: #FFF !important; }
    .azul-bg.violeta [data-type*="article"] i[class*="play"]:hover {
      color: #673AB7 !important; }
  .azul-bg.texto-blanco .article-shouts .info-wrapper .preview,
  .azul-bg.texto-blanco .article-shouts .info-wrapper .title,
  .azul-bg.texto-blanco .article-shouts .info-wrapper .author,
  .azul-bg.texto-blanco .article-shouts .info-wrapper .article-kicker,
  .azul-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview,
  .azul-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title,
  .azul-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author,
  .azul-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker,
  .azul-bg.texto-blanco .simple-list .info-wrapper .preview,
  .azul-bg.texto-blanco .simple-list .info-wrapper .title,
  .azul-bg.texto-blanco .simple-list .info-wrapper .author,
  .azul-bg.texto-blanco .simple-list .info-wrapper .article-kicker,
  .azul-bg.texto-blanco .simple-list-square-image .info-wrapper .preview,
  .azul-bg.texto-blanco .simple-list-square-image .info-wrapper .title,
  .azul-bg.texto-blanco .simple-list-square-image .info-wrapper .author,
  .azul-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker {
    color: #FFF !important; }
    .azul-bg.texto-blanco .article-shouts .info-wrapper .preview:hover,
    .azul-bg.texto-blanco .article-shouts .info-wrapper .title:hover,
    .azul-bg.texto-blanco .article-shouts .info-wrapper .author:hover,
    .azul-bg.texto-blanco .article-shouts .info-wrapper .article-kicker:hover,
    .azul-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview:hover,
    .azul-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title:hover,
    .azul-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author:hover,
    .azul-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker:hover,
    .azul-bg.texto-blanco .simple-list .info-wrapper .preview:hover,
    .azul-bg.texto-blanco .simple-list .info-wrapper .title:hover,
    .azul-bg.texto-blanco .simple-list .info-wrapper .author:hover,
    .azul-bg.texto-blanco .simple-list .info-wrapper .article-kicker:hover,
    .azul-bg.texto-blanco .simple-list-square-image .info-wrapper .preview:hover,
    .azul-bg.texto-blanco .simple-list-square-image .info-wrapper .title:hover,
    .azul-bg.texto-blanco .simple-list-square-image .info-wrapper .author:hover,
    .azul-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker:hover {
      color: #FFF !important; }
  .azul-bg.texto-blanco .article-shouts .preview,
  .azul-bg.texto-blanco .article-shouts .title,
  .azul-bg.texto-blanco .article-shouts .author,
  .azul-bg.texto-blanco .highlighted-note-square-image .preview,
  .azul-bg.texto-blanco .highlighted-note-square-image .title,
  .azul-bg.texto-blanco .highlighted-note-square-image .author,
  .azul-bg.texto-blanco .simple-list .preview,
  .azul-bg.texto-blanco .simple-list .title,
  .azul-bg.texto-blanco .simple-list .author,
  .azul-bg.texto-blanco .simple-list-square-image .preview,
  .azul-bg.texto-blanco .simple-list-square-image .title,
  .azul-bg.texto-blanco .simple-list-square-image .author {
    color: #FFF !important; }
    .azul-bg.texto-blanco .article-shouts .preview:hover,
    .azul-bg.texto-blanco .article-shouts .title:hover,
    .azul-bg.texto-blanco .article-shouts .author:hover,
    .azul-bg.texto-blanco .highlighted-note-square-image .preview:hover,
    .azul-bg.texto-blanco .highlighted-note-square-image .title:hover,
    .azul-bg.texto-blanco .highlighted-note-square-image .author:hover,
    .azul-bg.texto-blanco .simple-list .preview:hover,
    .azul-bg.texto-blanco .simple-list .title:hover,
    .azul-bg.texto-blanco .simple-list .author:hover,
    .azul-bg.texto-blanco .simple-list-square-image .preview:hover,
    .azul-bg.texto-blanco .simple-list-square-image .title:hover,
    .azul-bg.texto-blanco .simple-list-square-image .author:hover {
      color: #FFF !important; }
  .azul-bg.texto-blanco .standard-header {
    border-bottom-color: #FFF !important; }
    .azul-bg.texto-blanco .standard-header__title {
      color: #FFF !important; }
  .azul-bg.texto-blanco [class*='note'] .article-theme {
    color: #FFF !important; }
  .azul-bg.texto-blanco .headline {
    margin-top: 0;
    padding-top: 8px; }
    .azul-bg.texto-blanco .headline .obj-title {
      color: #FFF !important; }

/* Widgets con color relacionado */

.widget-wrapper.azul:not([class*="-bg"]) {
  /* Cabezales */
  /* Chapitas en los listados de notas */
  /* Icono de Play si la nota tiene video */ }
  .widget-wrapper.azul:not([class*="-bg"]) .headline .obj-title {
    color: #303F9F; }
  .widget-wrapper.azul:not([class*="-bg"]) [class*='note']:not(.highlighted-portrait-note):not(.highlighted-note-overprinted-title) .article-kicker {
    color: #303F9F !important; }
  .widget-wrapper.azul:not([class*="-bg"]) [class*='note'] .article-theme {
    background-color: rgba(48, 63, 159, 0.8) !important;
    color: #FFF !important; }
  .widget-wrapper.azul:not([class*="-bg"]) [class*='note'] .info-wrapper .preview:hover,
  .widget-wrapper.azul:not([class*="-bg"]) [class*='note'] .info-wrapper .title:hover,
  .widget-wrapper.azul:not([class*="-bg"]) [class*='note'] .info-wrapper .author:hover,
  .widget-wrapper.azul:not([class*="-bg"]) [class*='note'] .info-wrapper .article-category-grouper:hover,
  .widget-wrapper.azul:not([class*="-bg"]) [class*='note'] .info-wrapper .article-kicker:hover {
    color: #303F9F !important; }
  .widget-wrapper.azul:not([class*="-bg"]) [class*='note'] .preview:hover,
  .widget-wrapper.azul:not([class*="-bg"]) [class*='note'] .title:hover,
  .widget-wrapper.azul:not([class*="-bg"]) [class*='note'] .author:hover {
    color: #303F9F !important; }
  .widget-wrapper.azul:not([class*="-bg"]) [data-type*="article"] i[class*="play"]:hover {
    color: #303F9F !important; }

/* Widges con colores de fondo */
.celeste-bg {
  background-color: #03A9F4; }
  .celeste-bg .highlighted-note-square-image .preview,
  .celeste-bg .highlighted-note-square-image .title,
  .celeste-bg .highlighted-note-square-image .author,
  .celeste-bg .highlighted-note-square-image .article-category-grouper,
  .celeste-bg .highlighted-note-square-image .article-kicker,
  .celeste-bg .simple-note .preview,
  .celeste-bg .simple-note .title,
  .celeste-bg .simple-note .author,
  .celeste-bg .simple-note .article-category-grouper,
  .celeste-bg .simple-note .article-kicker,
  .celeste-bg .simple-note-square-image .preview,
  .celeste-bg .simple-note-square-image .title,
  .celeste-bg .simple-note-square-image .author,
  .celeste-bg .simple-note-square-image .article-category-grouper,
  .celeste-bg .simple-note-square-image .article-kicker {
    color: #FFF !important; }
  .celeste-bg .headline {
    margin-top: 0;
    padding-top: 8px; }
    .celeste-bg .headline .obj-title {
      color: #FFF !important; }
  .celeste-bg.negro {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .celeste-bg.negro [class*='note'] .article-theme {
      background-color: rgba(26, 26, 29, 0.8) !important;
      color: #FFF !important; }
    .celeste-bg.negro [data-type*="article"] i[class*="play"]:hover {
      color: #1A1A1D !important; }
  .celeste-bg.rojo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .celeste-bg.rojo [class*='note'] .article-theme {
      background-color: rgba(211, 47, 47, 0.8) !important;
      color: #FFF !important; }
    .celeste-bg.rojo [data-type*="article"] i[class*="play"]:hover {
      color: #D32F2F !important; }
  .celeste-bg.marron {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .celeste-bg.marron [class*='note'] .article-theme {
      background-color: rgba(121, 85, 72, 0.8) !important;
      color: #FFF !important; }
    .celeste-bg.marron [data-type*="article"] i[class*="play"]:hover {
      color: #795548 !important; }
  .celeste-bg.verde {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .celeste-bg.verde [class*='note'] .article-theme {
      background-color: rgba(136, 178, 68, 0.8) !important;
      color: #FFF !important; }
    .celeste-bg.verde [data-type*="article"] i[class*="play"]:hover {
      color: #88B244 !important; }
  .celeste-bg.ocre {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .celeste-bg.ocre [class*='note'] .article-theme {
      background-color: rgba(237, 217, 192, 0.8) !important;
      color: #000 !important; }
    .celeste-bg.ocre [data-type*="article"] i[class*="play"]:hover {
      color: #EDD9C0 !important; }
  .celeste-bg.gris {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .celeste-bg.gris [class*='note'] .article-theme {
      background-color: rgba(158, 158, 158, 0.8) !important;
      color: #FFF !important; }
    .celeste-bg.gris [data-type*="article"] i[class*="play"]:hover {
      color: #9E9E9E !important; }
  .celeste-bg.bordo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .celeste-bg.bordo [class*='note'] .article-theme {
      background-color: rgba(118, 50, 63, 0.8) !important;
      color: #FFF !important; }
    .celeste-bg.bordo [data-type*="article"] i[class*="play"]:hover {
      color: #76323F !important; }
  .celeste-bg.azul {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .celeste-bg.azul [class*='note'] .article-theme {
      background-color: rgba(48, 63, 159, 0.8) !important;
      color: #FFF !important; }
    .celeste-bg.azul [data-type*="article"] i[class*="play"]:hover {
      color: #303F9F !important; }
  .celeste-bg.celeste {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .celeste-bg.celeste [class*='note'] .article-theme {
      background-color: rgba(3, 169, 244, 0.8) !important;
      color: #FFF !important; }
    .celeste-bg.celeste [data-type*="article"] i[class*="play"]:hover {
      color: #03A9F4 !important; }
  .celeste-bg.amarillo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .celeste-bg.amarillo [class*='note'] .article-theme {
      background-color: rgba(247, 183, 51, 0.8) !important;
      color: #FFF !important; }
    .celeste-bg.amarillo [data-type*="article"] i[class*="play"]:hover {
      color: #F7B733 !important; }
  .celeste-bg.violeta {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .celeste-bg.violeta [class*='note'] .article-theme {
      background-color: rgba(103, 58, 183, 0.8) !important;
      color: #FFF !important; }
    .celeste-bg.violeta [data-type*="article"] i[class*="play"]:hover {
      color: #673AB7 !important; }
  .celeste-bg.texto-blanco .article-shouts .info-wrapper .preview,
  .celeste-bg.texto-blanco .article-shouts .info-wrapper .title,
  .celeste-bg.texto-blanco .article-shouts .info-wrapper .author,
  .celeste-bg.texto-blanco .article-shouts .info-wrapper .article-kicker,
  .celeste-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview,
  .celeste-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title,
  .celeste-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author,
  .celeste-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker,
  .celeste-bg.texto-blanco .simple-list .info-wrapper .preview,
  .celeste-bg.texto-blanco .simple-list .info-wrapper .title,
  .celeste-bg.texto-blanco .simple-list .info-wrapper .author,
  .celeste-bg.texto-blanco .simple-list .info-wrapper .article-kicker,
  .celeste-bg.texto-blanco .simple-list-square-image .info-wrapper .preview,
  .celeste-bg.texto-blanco .simple-list-square-image .info-wrapper .title,
  .celeste-bg.texto-blanco .simple-list-square-image .info-wrapper .author,
  .celeste-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker {
    color: #FFF !important; }
    .celeste-bg.texto-blanco .article-shouts .info-wrapper .preview:hover,
    .celeste-bg.texto-blanco .article-shouts .info-wrapper .title:hover,
    .celeste-bg.texto-blanco .article-shouts .info-wrapper .author:hover,
    .celeste-bg.texto-blanco .article-shouts .info-wrapper .article-kicker:hover,
    .celeste-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview:hover,
    .celeste-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title:hover,
    .celeste-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author:hover,
    .celeste-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker:hover,
    .celeste-bg.texto-blanco .simple-list .info-wrapper .preview:hover,
    .celeste-bg.texto-blanco .simple-list .info-wrapper .title:hover,
    .celeste-bg.texto-blanco .simple-list .info-wrapper .author:hover,
    .celeste-bg.texto-blanco .simple-list .info-wrapper .article-kicker:hover,
    .celeste-bg.texto-blanco .simple-list-square-image .info-wrapper .preview:hover,
    .celeste-bg.texto-blanco .simple-list-square-image .info-wrapper .title:hover,
    .celeste-bg.texto-blanco .simple-list-square-image .info-wrapper .author:hover,
    .celeste-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker:hover {
      color: #FFF !important; }
  .celeste-bg.texto-blanco .article-shouts .preview,
  .celeste-bg.texto-blanco .article-shouts .title,
  .celeste-bg.texto-blanco .article-shouts .author,
  .celeste-bg.texto-blanco .highlighted-note-square-image .preview,
  .celeste-bg.texto-blanco .highlighted-note-square-image .title,
  .celeste-bg.texto-blanco .highlighted-note-square-image .author,
  .celeste-bg.texto-blanco .simple-list .preview,
  .celeste-bg.texto-blanco .simple-list .title,
  .celeste-bg.texto-blanco .simple-list .author,
  .celeste-bg.texto-blanco .simple-list-square-image .preview,
  .celeste-bg.texto-blanco .simple-list-square-image .title,
  .celeste-bg.texto-blanco .simple-list-square-image .author {
    color: #FFF !important; }
    .celeste-bg.texto-blanco .article-shouts .preview:hover,
    .celeste-bg.texto-blanco .article-shouts .title:hover,
    .celeste-bg.texto-blanco .article-shouts .author:hover,
    .celeste-bg.texto-blanco .highlighted-note-square-image .preview:hover,
    .celeste-bg.texto-blanco .highlighted-note-square-image .title:hover,
    .celeste-bg.texto-blanco .highlighted-note-square-image .author:hover,
    .celeste-bg.texto-blanco .simple-list .preview:hover,
    .celeste-bg.texto-blanco .simple-list .title:hover,
    .celeste-bg.texto-blanco .simple-list .author:hover,
    .celeste-bg.texto-blanco .simple-list-square-image .preview:hover,
    .celeste-bg.texto-blanco .simple-list-square-image .title:hover,
    .celeste-bg.texto-blanco .simple-list-square-image .author:hover {
      color: #FFF !important; }
  .celeste-bg.texto-blanco .standard-header {
    border-bottom-color: #FFF !important; }
    .celeste-bg.texto-blanco .standard-header__title {
      color: #FFF !important; }
  .celeste-bg.texto-blanco [class*='note'] .article-theme {
    color: #FFF !important; }
  .celeste-bg.texto-blanco .headline {
    margin-top: 0;
    padding-top: 8px; }
    .celeste-bg.texto-blanco .headline .obj-title {
      color: #FFF !important; }

/* Widgets con color relacionado */

.widget-wrapper.celeste:not([class*="-bg"]) {
  /* Cabezales */
  /* Chapitas en los listados de notas */
  /* Icono de Play si la nota tiene video */ }
  .widget-wrapper.celeste:not([class*="-bg"]) .headline .obj-title {
    color: #03A9F4; }
  .widget-wrapper.celeste:not([class*="-bg"]) [class*='note']:not(.highlighted-portrait-note):not(.highlighted-note-overprinted-title) .article-kicker {
    color: #03A9F4 !important; }
  .widget-wrapper.celeste:not([class*="-bg"]) [class*='note'] .article-theme {
    background-color: rgba(3, 169, 244, 0.8) !important;
    color: #FFF !important; }
  .widget-wrapper.celeste:not([class*="-bg"]) [class*='note'] .info-wrapper .preview:hover,
  .widget-wrapper.celeste:not([class*="-bg"]) [class*='note'] .info-wrapper .title:hover,
  .widget-wrapper.celeste:not([class*="-bg"]) [class*='note'] .info-wrapper .author:hover,
  .widget-wrapper.celeste:not([class*="-bg"]) [class*='note'] .info-wrapper .article-category-grouper:hover,
  .widget-wrapper.celeste:not([class*="-bg"]) [class*='note'] .info-wrapper .article-kicker:hover {
    color: #03A9F4 !important; }
  .widget-wrapper.celeste:not([class*="-bg"]) [class*='note'] .preview:hover,
  .widget-wrapper.celeste:not([class*="-bg"]) [class*='note'] .title:hover,
  .widget-wrapper.celeste:not([class*="-bg"]) [class*='note'] .author:hover {
    color: #03A9F4 !important; }
  .widget-wrapper.celeste:not([class*="-bg"]) [data-type*="article"] i[class*="play"]:hover {
    color: #03A9F4 !important; }

/* Widges con colores de fondo */
.amarillo-bg {
  background-color: #F7B733; }
  .amarillo-bg .highlighted-note-square-image .preview,
  .amarillo-bg .highlighted-note-square-image .title,
  .amarillo-bg .highlighted-note-square-image .author,
  .amarillo-bg .highlighted-note-square-image .article-category-grouper,
  .amarillo-bg .highlighted-note-square-image .article-kicker,
  .amarillo-bg .simple-note .preview,
  .amarillo-bg .simple-note .title,
  .amarillo-bg .simple-note .author,
  .amarillo-bg .simple-note .article-category-grouper,
  .amarillo-bg .simple-note .article-kicker,
  .amarillo-bg .simple-note-square-image .preview,
  .amarillo-bg .simple-note-square-image .title,
  .amarillo-bg .simple-note-square-image .author,
  .amarillo-bg .simple-note-square-image .article-category-grouper,
  .amarillo-bg .simple-note-square-image .article-kicker {
    color: #FFF !important; }
  .amarillo-bg .headline {
    margin-top: 0;
    padding-top: 8px; }
    .amarillo-bg .headline .obj-title {
      color: #FFF !important; }
  .amarillo-bg.negro {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .amarillo-bg.negro [class*='note'] .article-theme {
      background-color: rgba(26, 26, 29, 0.8) !important;
      color: #FFF !important; }
    .amarillo-bg.negro [data-type*="article"] i[class*="play"]:hover {
      color: #1A1A1D !important; }
  .amarillo-bg.rojo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .amarillo-bg.rojo [class*='note'] .article-theme {
      background-color: rgba(211, 47, 47, 0.8) !important;
      color: #FFF !important; }
    .amarillo-bg.rojo [data-type*="article"] i[class*="play"]:hover {
      color: #D32F2F !important; }
  .amarillo-bg.marron {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .amarillo-bg.marron [class*='note'] .article-theme {
      background-color: rgba(121, 85, 72, 0.8) !important;
      color: #FFF !important; }
    .amarillo-bg.marron [data-type*="article"] i[class*="play"]:hover {
      color: #795548 !important; }
  .amarillo-bg.verde {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .amarillo-bg.verde [class*='note'] .article-theme {
      background-color: rgba(136, 178, 68, 0.8) !important;
      color: #FFF !important; }
    .amarillo-bg.verde [data-type*="article"] i[class*="play"]:hover {
      color: #88B244 !important; }
  .amarillo-bg.ocre {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .amarillo-bg.ocre [class*='note'] .article-theme {
      background-color: rgba(237, 217, 192, 0.8) !important;
      color: #000 !important; }
    .amarillo-bg.ocre [data-type*="article"] i[class*="play"]:hover {
      color: #EDD9C0 !important; }
  .amarillo-bg.gris {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .amarillo-bg.gris [class*='note'] .article-theme {
      background-color: rgba(158, 158, 158, 0.8) !important;
      color: #FFF !important; }
    .amarillo-bg.gris [data-type*="article"] i[class*="play"]:hover {
      color: #9E9E9E !important; }
  .amarillo-bg.bordo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .amarillo-bg.bordo [class*='note'] .article-theme {
      background-color: rgba(118, 50, 63, 0.8) !important;
      color: #FFF !important; }
    .amarillo-bg.bordo [data-type*="article"] i[class*="play"]:hover {
      color: #76323F !important; }
  .amarillo-bg.azul {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .amarillo-bg.azul [class*='note'] .article-theme {
      background-color: rgba(48, 63, 159, 0.8) !important;
      color: #FFF !important; }
    .amarillo-bg.azul [data-type*="article"] i[class*="play"]:hover {
      color: #303F9F !important; }
  .amarillo-bg.celeste {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .amarillo-bg.celeste [class*='note'] .article-theme {
      background-color: rgba(3, 169, 244, 0.8) !important;
      color: #FFF !important; }
    .amarillo-bg.celeste [data-type*="article"] i[class*="play"]:hover {
      color: #03A9F4 !important; }
  .amarillo-bg.amarillo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .amarillo-bg.amarillo [class*='note'] .article-theme {
      background-color: rgba(247, 183, 51, 0.8) !important;
      color: #FFF !important; }
    .amarillo-bg.amarillo [data-type*="article"] i[class*="play"]:hover {
      color: #F7B733 !important; }
  .amarillo-bg.violeta {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .amarillo-bg.violeta [class*='note'] .article-theme {
      background-color: rgba(103, 58, 183, 0.8) !important;
      color: #FFF !important; }
    .amarillo-bg.violeta [data-type*="article"] i[class*="play"]:hover {
      color: #673AB7 !important; }
  .amarillo-bg.texto-blanco .article-shouts .info-wrapper .preview,
  .amarillo-bg.texto-blanco .article-shouts .info-wrapper .title,
  .amarillo-bg.texto-blanco .article-shouts .info-wrapper .author,
  .amarillo-bg.texto-blanco .article-shouts .info-wrapper .article-kicker,
  .amarillo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview,
  .amarillo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title,
  .amarillo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author,
  .amarillo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker,
  .amarillo-bg.texto-blanco .simple-list .info-wrapper .preview,
  .amarillo-bg.texto-blanco .simple-list .info-wrapper .title,
  .amarillo-bg.texto-blanco .simple-list .info-wrapper .author,
  .amarillo-bg.texto-blanco .simple-list .info-wrapper .article-kicker,
  .amarillo-bg.texto-blanco .simple-list-square-image .info-wrapper .preview,
  .amarillo-bg.texto-blanco .simple-list-square-image .info-wrapper .title,
  .amarillo-bg.texto-blanco .simple-list-square-image .info-wrapper .author,
  .amarillo-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker {
    color: #FFF !important; }
    .amarillo-bg.texto-blanco .article-shouts .info-wrapper .preview:hover,
    .amarillo-bg.texto-blanco .article-shouts .info-wrapper .title:hover,
    .amarillo-bg.texto-blanco .article-shouts .info-wrapper .author:hover,
    .amarillo-bg.texto-blanco .article-shouts .info-wrapper .article-kicker:hover,
    .amarillo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview:hover,
    .amarillo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title:hover,
    .amarillo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author:hover,
    .amarillo-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker:hover,
    .amarillo-bg.texto-blanco .simple-list .info-wrapper .preview:hover,
    .amarillo-bg.texto-blanco .simple-list .info-wrapper .title:hover,
    .amarillo-bg.texto-blanco .simple-list .info-wrapper .author:hover,
    .amarillo-bg.texto-blanco .simple-list .info-wrapper .article-kicker:hover,
    .amarillo-bg.texto-blanco .simple-list-square-image .info-wrapper .preview:hover,
    .amarillo-bg.texto-blanco .simple-list-square-image .info-wrapper .title:hover,
    .amarillo-bg.texto-blanco .simple-list-square-image .info-wrapper .author:hover,
    .amarillo-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker:hover {
      color: #FFF !important; }
  .amarillo-bg.texto-blanco .article-shouts .preview,
  .amarillo-bg.texto-blanco .article-shouts .title,
  .amarillo-bg.texto-blanco .article-shouts .author,
  .amarillo-bg.texto-blanco .highlighted-note-square-image .preview,
  .amarillo-bg.texto-blanco .highlighted-note-square-image .title,
  .amarillo-bg.texto-blanco .highlighted-note-square-image .author,
  .amarillo-bg.texto-blanco .simple-list .preview,
  .amarillo-bg.texto-blanco .simple-list .title,
  .amarillo-bg.texto-blanco .simple-list .author,
  .amarillo-bg.texto-blanco .simple-list-square-image .preview,
  .amarillo-bg.texto-blanco .simple-list-square-image .title,
  .amarillo-bg.texto-blanco .simple-list-square-image .author {
    color: #FFF !important; }
    .amarillo-bg.texto-blanco .article-shouts .preview:hover,
    .amarillo-bg.texto-blanco .article-shouts .title:hover,
    .amarillo-bg.texto-blanco .article-shouts .author:hover,
    .amarillo-bg.texto-blanco .highlighted-note-square-image .preview:hover,
    .amarillo-bg.texto-blanco .highlighted-note-square-image .title:hover,
    .amarillo-bg.texto-blanco .highlighted-note-square-image .author:hover,
    .amarillo-bg.texto-blanco .simple-list .preview:hover,
    .amarillo-bg.texto-blanco .simple-list .title:hover,
    .amarillo-bg.texto-blanco .simple-list .author:hover,
    .amarillo-bg.texto-blanco .simple-list-square-image .preview:hover,
    .amarillo-bg.texto-blanco .simple-list-square-image .title:hover,
    .amarillo-bg.texto-blanco .simple-list-square-image .author:hover {
      color: #FFF !important; }
  .amarillo-bg.texto-blanco .standard-header {
    border-bottom-color: #FFF !important; }
    .amarillo-bg.texto-blanco .standard-header__title {
      color: #FFF !important; }
  .amarillo-bg.texto-blanco [class*='note'] .article-theme {
    color: #FFF !important; }
  .amarillo-bg.texto-blanco .headline {
    margin-top: 0;
    padding-top: 8px; }
    .amarillo-bg.texto-blanco .headline .obj-title {
      color: #FFF !important; }

/* Widgets con color relacionado */

.widget-wrapper.amarillo:not([class*="-bg"]) {
  /* Cabezales */
  /* Chapitas en los listados de notas */
  /* Icono de Play si la nota tiene video */ }
  .widget-wrapper.amarillo:not([class*="-bg"]) .headline .obj-title {
    color: #F7B733; }
  .widget-wrapper.amarillo:not([class*="-bg"]) [class*='note']:not(.highlighted-portrait-note):not(.highlighted-note-overprinted-title) .article-kicker {
    color: #F7B733 !important; }
  .widget-wrapper.amarillo:not([class*="-bg"]) [class*='note'] .article-theme {
    background-color: rgba(247, 183, 51, 0.8) !important;
    color: #FFF !important; }
  .widget-wrapper.amarillo:not([class*="-bg"]) [class*='note'] .info-wrapper .preview:hover,
  .widget-wrapper.amarillo:not([class*="-bg"]) [class*='note'] .info-wrapper .title:hover,
  .widget-wrapper.amarillo:not([class*="-bg"]) [class*='note'] .info-wrapper .author:hover,
  .widget-wrapper.amarillo:not([class*="-bg"]) [class*='note'] .info-wrapper .article-category-grouper:hover,
  .widget-wrapper.amarillo:not([class*="-bg"]) [class*='note'] .info-wrapper .article-kicker:hover {
    color: #F7B733 !important; }
  .widget-wrapper.amarillo:not([class*="-bg"]) [class*='note'] .preview:hover,
  .widget-wrapper.amarillo:not([class*="-bg"]) [class*='note'] .title:hover,
  .widget-wrapper.amarillo:not([class*="-bg"]) [class*='note'] .author:hover {
    color: #F7B733 !important; }
  .widget-wrapper.amarillo:not([class*="-bg"]) [data-type*="article"] i[class*="play"]:hover {
    color: #F7B733 !important; }

/* Widges con colores de fondo */
.violeta-bg {
  background-color: #673AB7; }
  .violeta-bg .highlighted-note-square-image .preview,
  .violeta-bg .highlighted-note-square-image .title,
  .violeta-bg .highlighted-note-square-image .author,
  .violeta-bg .highlighted-note-square-image .article-category-grouper,
  .violeta-bg .highlighted-note-square-image .article-kicker,
  .violeta-bg .simple-note .preview,
  .violeta-bg .simple-note .title,
  .violeta-bg .simple-note .author,
  .violeta-bg .simple-note .article-category-grouper,
  .violeta-bg .simple-note .article-kicker,
  .violeta-bg .simple-note-square-image .preview,
  .violeta-bg .simple-note-square-image .title,
  .violeta-bg .simple-note-square-image .author,
  .violeta-bg .simple-note-square-image .article-category-grouper,
  .violeta-bg .simple-note-square-image .article-kicker {
    color: #FFF !important; }
  .violeta-bg .headline {
    margin-top: 0;
    padding-top: 8px; }
    .violeta-bg .headline .obj-title {
      color: #FFF !important; }
  .violeta-bg.negro {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .violeta-bg.negro [class*='note'] .article-theme {
      background-color: rgba(26, 26, 29, 0.8) !important;
      color: #FFF !important; }
    .violeta-bg.negro [data-type*="article"] i[class*="play"]:hover {
      color: #1A1A1D !important; }
  .violeta-bg.rojo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .violeta-bg.rojo [class*='note'] .article-theme {
      background-color: rgba(211, 47, 47, 0.8) !important;
      color: #FFF !important; }
    .violeta-bg.rojo [data-type*="article"] i[class*="play"]:hover {
      color: #D32F2F !important; }
  .violeta-bg.marron {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .violeta-bg.marron [class*='note'] .article-theme {
      background-color: rgba(121, 85, 72, 0.8) !important;
      color: #FFF !important; }
    .violeta-bg.marron [data-type*="article"] i[class*="play"]:hover {
      color: #795548 !important; }
  .violeta-bg.verde {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .violeta-bg.verde [class*='note'] .article-theme {
      background-color: rgba(136, 178, 68, 0.8) !important;
      color: #FFF !important; }
    .violeta-bg.verde [data-type*="article"] i[class*="play"]:hover {
      color: #88B244 !important; }
  .violeta-bg.ocre {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .violeta-bg.ocre [class*='note'] .article-theme {
      background-color: rgba(237, 217, 192, 0.8) !important;
      color: #000 !important; }
    .violeta-bg.ocre [data-type*="article"] i[class*="play"]:hover {
      color: #EDD9C0 !important; }
  .violeta-bg.gris {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .violeta-bg.gris [class*='note'] .article-theme {
      background-color: rgba(158, 158, 158, 0.8) !important;
      color: #FFF !important; }
    .violeta-bg.gris [data-type*="article"] i[class*="play"]:hover {
      color: #9E9E9E !important; }
  .violeta-bg.bordo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .violeta-bg.bordo [class*='note'] .article-theme {
      background-color: rgba(118, 50, 63, 0.8) !important;
      color: #FFF !important; }
    .violeta-bg.bordo [data-type*="article"] i[class*="play"]:hover {
      color: #76323F !important; }
  .violeta-bg.azul {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .violeta-bg.azul [class*='note'] .article-theme {
      background-color: rgba(48, 63, 159, 0.8) !important;
      color: #FFF !important; }
    .violeta-bg.azul [data-type*="article"] i[class*="play"]:hover {
      color: #303F9F !important; }
  .violeta-bg.celeste {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .violeta-bg.celeste [class*='note'] .article-theme {
      background-color: rgba(3, 169, 244, 0.8) !important;
      color: #FFF !important; }
    .violeta-bg.celeste [data-type*="article"] i[class*="play"]:hover {
      color: #03A9F4 !important; }
  .violeta-bg.amarillo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .violeta-bg.amarillo [class*='note'] .article-theme {
      background-color: rgba(247, 183, 51, 0.8) !important;
      color: #FFF !important; }
    .violeta-bg.amarillo [data-type*="article"] i[class*="play"]:hover {
      color: #F7B733 !important; }
  .violeta-bg.violeta {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .violeta-bg.violeta [class*='note'] .article-theme {
      background-color: rgba(103, 58, 183, 0.8) !important;
      color: #FFF !important; }
    .violeta-bg.violeta [data-type*="article"] i[class*="play"]:hover {
      color: #673AB7 !important; }
  .violeta-bg.texto-blanco .article-shouts .info-wrapper .preview,
  .violeta-bg.texto-blanco .article-shouts .info-wrapper .title,
  .violeta-bg.texto-blanco .article-shouts .info-wrapper .author,
  .violeta-bg.texto-blanco .article-shouts .info-wrapper .article-kicker,
  .violeta-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview,
  .violeta-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title,
  .violeta-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author,
  .violeta-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker,
  .violeta-bg.texto-blanco .simple-list .info-wrapper .preview,
  .violeta-bg.texto-blanco .simple-list .info-wrapper .title,
  .violeta-bg.texto-blanco .simple-list .info-wrapper .author,
  .violeta-bg.texto-blanco .simple-list .info-wrapper .article-kicker,
  .violeta-bg.texto-blanco .simple-list-square-image .info-wrapper .preview,
  .violeta-bg.texto-blanco .simple-list-square-image .info-wrapper .title,
  .violeta-bg.texto-blanco .simple-list-square-image .info-wrapper .author,
  .violeta-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker {
    color: #FFF !important; }
    .violeta-bg.texto-blanco .article-shouts .info-wrapper .preview:hover,
    .violeta-bg.texto-blanco .article-shouts .info-wrapper .title:hover,
    .violeta-bg.texto-blanco .article-shouts .info-wrapper .author:hover,
    .violeta-bg.texto-blanco .article-shouts .info-wrapper .article-kicker:hover,
    .violeta-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview:hover,
    .violeta-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title:hover,
    .violeta-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author:hover,
    .violeta-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker:hover,
    .violeta-bg.texto-blanco .simple-list .info-wrapper .preview:hover,
    .violeta-bg.texto-blanco .simple-list .info-wrapper .title:hover,
    .violeta-bg.texto-blanco .simple-list .info-wrapper .author:hover,
    .violeta-bg.texto-blanco .simple-list .info-wrapper .article-kicker:hover,
    .violeta-bg.texto-blanco .simple-list-square-image .info-wrapper .preview:hover,
    .violeta-bg.texto-blanco .simple-list-square-image .info-wrapper .title:hover,
    .violeta-bg.texto-blanco .simple-list-square-image .info-wrapper .author:hover,
    .violeta-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker:hover {
      color: #FFF !important; }
  .violeta-bg.texto-blanco .article-shouts .preview,
  .violeta-bg.texto-blanco .article-shouts .title,
  .violeta-bg.texto-blanco .article-shouts .author,
  .violeta-bg.texto-blanco .highlighted-note-square-image .preview,
  .violeta-bg.texto-blanco .highlighted-note-square-image .title,
  .violeta-bg.texto-blanco .highlighted-note-square-image .author,
  .violeta-bg.texto-blanco .simple-list .preview,
  .violeta-bg.texto-blanco .simple-list .title,
  .violeta-bg.texto-blanco .simple-list .author,
  .violeta-bg.texto-blanco .simple-list-square-image .preview,
  .violeta-bg.texto-blanco .simple-list-square-image .title,
  .violeta-bg.texto-blanco .simple-list-square-image .author {
    color: #FFF !important; }
    .violeta-bg.texto-blanco .article-shouts .preview:hover,
    .violeta-bg.texto-blanco .article-shouts .title:hover,
    .violeta-bg.texto-blanco .article-shouts .author:hover,
    .violeta-bg.texto-blanco .highlighted-note-square-image .preview:hover,
    .violeta-bg.texto-blanco .highlighted-note-square-image .title:hover,
    .violeta-bg.texto-blanco .highlighted-note-square-image .author:hover,
    .violeta-bg.texto-blanco .simple-list .preview:hover,
    .violeta-bg.texto-blanco .simple-list .title:hover,
    .violeta-bg.texto-blanco .simple-list .author:hover,
    .violeta-bg.texto-blanco .simple-list-square-image .preview:hover,
    .violeta-bg.texto-blanco .simple-list-square-image .title:hover,
    .violeta-bg.texto-blanco .simple-list-square-image .author:hover {
      color: #FFF !important; }
  .violeta-bg.texto-blanco .standard-header {
    border-bottom-color: #FFF !important; }
    .violeta-bg.texto-blanco .standard-header__title {
      color: #FFF !important; }
  .violeta-bg.texto-blanco [class*='note'] .article-theme {
    color: #FFF !important; }
  .violeta-bg.texto-blanco .headline {
    margin-top: 0;
    padding-top: 8px; }
    .violeta-bg.texto-blanco .headline .obj-title {
      color: #FFF !important; }

/* Widgets con color relacionado */

.widget-wrapper.violeta:not([class*="-bg"]) {
  /* Cabezales */
  /* Chapitas en los listados de notas */
  /* Icono de Play si la nota tiene video */ }
  .widget-wrapper.violeta:not([class*="-bg"]) .headline .obj-title {
    color: #673AB7; }
  .widget-wrapper.violeta:not([class*="-bg"]) [class*='note']:not(.highlighted-portrait-note):not(.highlighted-note-overprinted-title) .article-kicker {
    color: #673AB7 !important; }
  .widget-wrapper.violeta:not([class*="-bg"]) [class*='note'] .article-theme {
    background-color: rgba(103, 58, 183, 0.8) !important;
    color: #FFF !important; }
  .widget-wrapper.violeta:not([class*="-bg"]) [class*='note'] .info-wrapper .preview:hover,
  .widget-wrapper.violeta:not([class*="-bg"]) [class*='note'] .info-wrapper .title:hover,
  .widget-wrapper.violeta:not([class*="-bg"]) [class*='note'] .info-wrapper .author:hover,
  .widget-wrapper.violeta:not([class*="-bg"]) [class*='note'] .info-wrapper .article-category-grouper:hover,
  .widget-wrapper.violeta:not([class*="-bg"]) [class*='note'] .info-wrapper .article-kicker:hover {
    color: #673AB7 !important; }
  .widget-wrapper.violeta:not([class*="-bg"]) [class*='note'] .preview:hover,
  .widget-wrapper.violeta:not([class*="-bg"]) [class*='note'] .title:hover,
  .widget-wrapper.violeta:not([class*="-bg"]) [class*='note'] .author:hover {
    color: #673AB7 !important; }
  .widget-wrapper.violeta:not([class*="-bg"]) [data-type*="article"] i[class*="play"]:hover {
    color: #673AB7 !important; }

/* Widges con colores de fondo */
.texto-blanco-bg {
  background-color: #FFF; }
  .texto-blanco-bg .highlighted-note-square-image .preview,
  .texto-blanco-bg .highlighted-note-square-image .title,
  .texto-blanco-bg .highlighted-note-square-image .author,
  .texto-blanco-bg .highlighted-note-square-image .article-category-grouper,
  .texto-blanco-bg .highlighted-note-square-image .article-kicker,
  .texto-blanco-bg .simple-note .preview,
  .texto-blanco-bg .simple-note .title,
  .texto-blanco-bg .simple-note .author,
  .texto-blanco-bg .simple-note .article-category-grouper,
  .texto-blanco-bg .simple-note .article-kicker,
  .texto-blanco-bg .simple-note-square-image .preview,
  .texto-blanco-bg .simple-note-square-image .title,
  .texto-blanco-bg .simple-note-square-image .author,
  .texto-blanco-bg .simple-note-square-image .article-category-grouper,
  .texto-blanco-bg .simple-note-square-image .article-kicker {
    color: #FFF !important; }
  .texto-blanco-bg .headline {
    margin-top: 0;
    padding-top: 8px; }
    .texto-blanco-bg .headline .obj-title {
      color: #FFF !important; }
  .texto-blanco-bg.negro {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .texto-blanco-bg.negro [class*='note'] .article-theme {
      background-color: rgba(26, 26, 29, 0.8) !important;
      color: #FFF !important; }
    .texto-blanco-bg.negro [data-type*="article"] i[class*="play"]:hover {
      color: #1A1A1D !important; }
  .texto-blanco-bg.rojo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .texto-blanco-bg.rojo [class*='note'] .article-theme {
      background-color: rgba(211, 47, 47, 0.8) !important;
      color: #FFF !important; }
    .texto-blanco-bg.rojo [data-type*="article"] i[class*="play"]:hover {
      color: #D32F2F !important; }
  .texto-blanco-bg.marron {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .texto-blanco-bg.marron [class*='note'] .article-theme {
      background-color: rgba(121, 85, 72, 0.8) !important;
      color: #FFF !important; }
    .texto-blanco-bg.marron [data-type*="article"] i[class*="play"]:hover {
      color: #795548 !important; }
  .texto-blanco-bg.verde {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .texto-blanco-bg.verde [class*='note'] .article-theme {
      background-color: rgba(136, 178, 68, 0.8) !important;
      color: #FFF !important; }
    .texto-blanco-bg.verde [data-type*="article"] i[class*="play"]:hover {
      color: #88B244 !important; }
  .texto-blanco-bg.ocre {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .texto-blanco-bg.ocre [class*='note'] .article-theme {
      background-color: rgba(237, 217, 192, 0.8) !important;
      color: #000 !important; }
    .texto-blanco-bg.ocre [data-type*="article"] i[class*="play"]:hover {
      color: #EDD9C0 !important; }
  .texto-blanco-bg.gris {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .texto-blanco-bg.gris [class*='note'] .article-theme {
      background-color: rgba(158, 158, 158, 0.8) !important;
      color: #FFF !important; }
    .texto-blanco-bg.gris [data-type*="article"] i[class*="play"]:hover {
      color: #9E9E9E !important; }
  .texto-blanco-bg.bordo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .texto-blanco-bg.bordo [class*='note'] .article-theme {
      background-color: rgba(118, 50, 63, 0.8) !important;
      color: #FFF !important; }
    .texto-blanco-bg.bordo [data-type*="article"] i[class*="play"]:hover {
      color: #76323F !important; }
  .texto-blanco-bg.azul {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .texto-blanco-bg.azul [class*='note'] .article-theme {
      background-color: rgba(48, 63, 159, 0.8) !important;
      color: #FFF !important; }
    .texto-blanco-bg.azul [data-type*="article"] i[class*="play"]:hover {
      color: #303F9F !important; }
  .texto-blanco-bg.celeste {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .texto-blanco-bg.celeste [class*='note'] .article-theme {
      background-color: rgba(3, 169, 244, 0.8) !important;
      color: #FFF !important; }
    .texto-blanco-bg.celeste [data-type*="article"] i[class*="play"]:hover {
      color: #03A9F4 !important; }
  .texto-blanco-bg.amarillo {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .texto-blanco-bg.amarillo [class*='note'] .article-theme {
      background-color: rgba(247, 183, 51, 0.8) !important;
      color: #FFF !important; }
    .texto-blanco-bg.amarillo [data-type*="article"] i[class*="play"]:hover {
      color: #F7B733 !important; }
  .texto-blanco-bg.violeta {
    /* Chapitas en los listados de notas */
    /* Icono de Play si la nota tiene video */ }
    .texto-blanco-bg.violeta [class*='note'] .article-theme {
      background-color: rgba(103, 58, 183, 0.8) !important;
      color: #FFF !important; }
    .texto-blanco-bg.violeta [data-type*="article"] i[class*="play"]:hover {
      color: #673AB7 !important; }
  .texto-blanco-bg.texto-blanco .article-shouts .info-wrapper .preview,
  .texto-blanco-bg.texto-blanco .article-shouts .info-wrapper .title,
  .texto-blanco-bg.texto-blanco .article-shouts .info-wrapper .author,
  .texto-blanco-bg.texto-blanco .article-shouts .info-wrapper .article-kicker,
  .texto-blanco-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview,
  .texto-blanco-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title,
  .texto-blanco-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author,
  .texto-blanco-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker,
  .texto-blanco-bg.texto-blanco .simple-list .info-wrapper .preview,
  .texto-blanco-bg.texto-blanco .simple-list .info-wrapper .title,
  .texto-blanco-bg.texto-blanco .simple-list .info-wrapper .author,
  .texto-blanco-bg.texto-blanco .simple-list .info-wrapper .article-kicker,
  .texto-blanco-bg.texto-blanco .simple-list-square-image .info-wrapper .preview,
  .texto-blanco-bg.texto-blanco .simple-list-square-image .info-wrapper .title,
  .texto-blanco-bg.texto-blanco .simple-list-square-image .info-wrapper .author,
  .texto-blanco-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker {
    color: #FFF !important; }
    .texto-blanco-bg.texto-blanco .article-shouts .info-wrapper .preview:hover,
    .texto-blanco-bg.texto-blanco .article-shouts .info-wrapper .title:hover,
    .texto-blanco-bg.texto-blanco .article-shouts .info-wrapper .author:hover,
    .texto-blanco-bg.texto-blanco .article-shouts .info-wrapper .article-kicker:hover,
    .texto-blanco-bg.texto-blanco .highlighted-note-square-image .info-wrapper .preview:hover,
    .texto-blanco-bg.texto-blanco .highlighted-note-square-image .info-wrapper .title:hover,
    .texto-blanco-bg.texto-blanco .highlighted-note-square-image .info-wrapper .author:hover,
    .texto-blanco-bg.texto-blanco .highlighted-note-square-image .info-wrapper .article-kicker:hover,
    .texto-blanco-bg.texto-blanco .simple-list .info-wrapper .preview:hover,
    .texto-blanco-bg.texto-blanco .simple-list .info-wrapper .title:hover,
    .texto-blanco-bg.texto-blanco .simple-list .info-wrapper .author:hover,
    .texto-blanco-bg.texto-blanco .simple-list .info-wrapper .article-kicker:hover,
    .texto-blanco-bg.texto-blanco .simple-list-square-image .info-wrapper .preview:hover,
    .texto-blanco-bg.texto-blanco .simple-list-square-image .info-wrapper .title:hover,
    .texto-blanco-bg.texto-blanco .simple-list-square-image .info-wrapper .author:hover,
    .texto-blanco-bg.texto-blanco .simple-list-square-image .info-wrapper .article-kicker:hover {
      color: #FFF !important; }
  .texto-blanco-bg.texto-blanco .article-shouts .preview,
  .texto-blanco-bg.texto-blanco .article-shouts .title,
  .texto-blanco-bg.texto-blanco .article-shouts .author,
  .texto-blanco-bg.texto-blanco .highlighted-note-square-image .preview,
  .texto-blanco-bg.texto-blanco .highlighted-note-square-image .title,
  .texto-blanco-bg.texto-blanco .highlighted-note-square-image .author,
  .texto-blanco-bg.texto-blanco .simple-list .preview,
  .texto-blanco-bg.texto-blanco .simple-list .title,
  .texto-blanco-bg.texto-blanco .simple-list .author,
  .texto-blanco-bg.texto-blanco .simple-list-square-image .preview,
  .texto-blanco-bg.texto-blanco .simple-list-square-image .title,
  .texto-blanco-bg.texto-blanco .simple-list-square-image .author {
    color: #FFF !important; }
    .texto-blanco-bg.texto-blanco .article-shouts .preview:hover,
    .texto-blanco-bg.texto-blanco .article-shouts .title:hover,
    .texto-blanco-bg.texto-blanco .article-shouts .author:hover,
    .texto-blanco-bg.texto-blanco .highlighted-note-square-image .preview:hover,
    .texto-blanco-bg.texto-blanco .highlighted-note-square-image .title:hover,
    .texto-blanco-bg.texto-blanco .highlighted-note-square-image .author:hover,
    .texto-blanco-bg.texto-blanco .simple-list .preview:hover,
    .texto-blanco-bg.texto-blanco .simple-list .title:hover,
    .texto-blanco-bg.texto-blanco .simple-list .author:hover,
    .texto-blanco-bg.texto-blanco .simple-list-square-image .preview:hover,
    .texto-blanco-bg.texto-blanco .simple-list-square-image .title:hover,
    .texto-blanco-bg.texto-blanco .simple-list-square-image .author:hover {
      color: #FFF !important; }
  .texto-blanco-bg.texto-blanco .standard-header {
    border-bottom-color: #FFF !important; }
    .texto-blanco-bg.texto-blanco .standard-header__title {
      color: #FFF !important; }
  .texto-blanco-bg.texto-blanco [class*='note'] .article-theme {
    color: #FFF !important; }
  .texto-blanco-bg.texto-blanco .headline {
    margin-top: 0;
    padding-top: 8px; }
    .texto-blanco-bg.texto-blanco .headline .obj-title {
      color: #FFF !important; }

/* Widgets con color relacionado */
.widget-wrapper.texto-blanco {
  color: #FFF;
  /* Cabezales */ }
  .widget-wrapper.texto-blanco .headline .obj-title {
    color: #FFF; }
  .widget-wrapper.texto-blanco .standard-header {
    border-bottom-color: #FFF !important; }
    .widget-wrapper.texto-blanco .standard-header__title {
      color: #FFF !important; }
  .widget-wrapper.texto-blanco .simple-list .simple-note:not(.no-media) .title {
    color: #FFF; }
  .widget-wrapper.texto-blanco .simple-list-square-image .simple-note-square-image .title {
    color: #FFF; }
  .widget-wrapper.texto-blanco .free-text .body-content {
    color: #FFF; }
  .widget-wrapper.texto-blanco [data-type*="article"] .info-wrapper .article-kicker {
    color: #FFF !important; }
/********** [-end- NECESSARY STYLES FOR EVERY PAGE -end-] **********/
/********** [ -start- HEADER STYLES -start- ] **********/
/* Template */
.main-header {
  margin-bottom: 0.9375rem;
  box-shadow: 0 0.125rem 0.5rem rgba(51, 51, 51, 0.1); }
  .main-header__middle {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    min-height: 3.5rem;
    padding: 0 0.9375rem; }
  .main-header__middle-region {
    display: flex;
    align-items: center;
    flex: 0 0 33.333333%;
    flex-direction: row;
    max-width: 33.333333%; }
  .main-header__sticky-wrapper {
    background-color: #FFF; }
  .main-header__hidden-nav {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-right: auto; }
  .main-header__menu-toggler {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    width: 1.25rem;
    height: 1.0625rem;
    cursor: pointer; }
  .main-header__menu-toggler-bar {
    width: 100%;
    height: 0.1875rem;
    background-color: #424242; }
    .main-header__menu-toggler-bar:nth-of-type(2) {
      width: 50%;
      transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      will-change: width; }
  .main-header__search-toggler {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1.25rem;
    cursor: pointer; }
    .main-header__search-toggler i[class^='icon--search'] {
      min-width: 1.5rem;
      min-height: 1.5rem;
      mask-size: 1.2rem 1.2rem;
      -webkit-mask-size: 1.2rem 1.2rem; }
  .main-header__bottom {
    padding: 0 1.25rem; }
  .main-header__bottom, .main-header__bottom-region {
    display: flex;
    align-items: center;
    flex-direction: row;
    min-height: 2.25rem; }
  .main-header__bottom-region:first-of-type, .main-header__bottom-region:last-of-type {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .main-header__bottom-region:nth-last-of-type(2) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
@keyframes headerSticky {
  from {
    top: -3.75rem; }
  to {
    top: 0; } }

@keyframes headerStickyWithBanner {
  from {
    top: -3.75rem; }
  to {
    top: var(--banner-header-height); } }

body.body--header-sticky {
  padding-top: var(--header-height);
  /* con banner */ }
  body.body--header-sticky .main-header__sticky-wrapper {
    position: fixed;
    z-index: 8;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    min-height: 3.5rem;
    box-shadow: 0 2px 8px rgba(51, 51, 51, 0.1);
    animation: headerSticky cubic-bezier(0.4, 0, 0.2, 1) 250ms 1 normal forwards; }
  body.body--header-sticky.body--banner-sticky {
    padding-top: var(--header-height); }
    body.body--header-sticky.body--banner-sticky .main-header__sticky-wrapper {
      top: var(--banner-header-height);
      animation: headerStickyWithBanner cubic-bezier(0.4, 0, 0.2, 1) 250ms 1 normal forwards; }
    body.body--header-sticky.body--banner-sticky .header-ad {
      position: fixed;
      z-index: 8;
      top: 0;
      right: 0;
      bottom: auto;
      left: 0;
      background-color: #E0E0E0;
      animation: headerSticky cubic-bezier(0.4, 0, 0.2, 1) 250ms 1 normal forwards; }

/* Buscador */
.search-form {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); }

@keyframes showSearch {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  .search-form__form-control, .search-form__submit-btn {
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    backface-visibility: hidden;
    transform-style: preserve-3d; }
  .search-form__form-control[type='search'] {
    font-size: 1.5em;
    line-height: 1;
    top: 50vh;
    width: calc(100vw - 2.5rem);
    min-height: 3.125rem;
    padding: 0.625rem;
    text-align: left;
    vertical-align: middle;
    color: #FFF;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 0 solid transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 0 solid transparent;
    outline: 0;
    background: transparent !important;
    border-radius: 0;
    transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
    .search-form__form-control[type='search']:hover, .search-form__form-control[type='search']:focus, .search-form__form-control[type='search']:active {
      border-top: 1px solid rgba(255, 255, 255, 0.8);
      border-right: 0 solid transparent;
      border-bottom: 1px solid rgba(255, 255, 255, 0.8);
      border-left: 0 solid transparent;
      outline: 0; }
    .search-form__form-control[type='search']::-webkit-search-cancel-button {
      display: none; }
    .search-form__form-control[type='search']::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      text-align: center; }
    .search-form__form-control[type='search']::-moz-placeholder {
      /* Firefox 19+ */
      text-align: center; }
    .search-form__form-control[type='search']:-ms-input-placeholder {
      /* IE 10+ */
      text-align: center; }
    .search-form__form-control[type='search']:-moz-placeholder {
      /* Firefox 18- */
      text-align: center; }
    .search-form__form-control[type='search'] + label[for='searchInput'] {
      font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
      font-size: 1.5em;
      font-weight: 700;
      position: absolute;
      top: calc(50vh - 2.5rem);
      left: 50%;
      white-space: nowrap;
      color: #FFF;
      transform: translate3d(-50%, 0, 0);
      backface-visibility: hidden;
      transform-style: preserve-3d; }
      .search-form__form-control[type='search'] + label[for='searchInput'] em:first-letter {
        text-transform: uppercase; }
  .search-form__submit-btn[type='submit'] {
    top: calc(50vh + 4.375rem);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border: 0 none;
    background-color: #0994DC;
    border-radius: 100%;
    transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
    .search-form__submit-btn[type='submit'] i[class^='icon'] {
      min-width: 1.25rem;
      min-height: 1.25rem; }
    .search-form__submit-btn[type='submit']:hover {
      background-color: #066393; }
  .search-form__close-btn {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.875rem;
    height: 1.875rem;
    cursor: pointer;
    background-color: #EB5757;
    transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 100%; }
    .search-form__close-btn i[class^='icon'] {
      min-width: 0.9375rem;
      min-height: 0.9375rem; }
    .search-form__close-btn:hover {
      background-color: #db1a1a; }

#searchToggler:checked ~ .search-form {
  display: block;
  animation: showSearch 0.2s cubic-bezier(0.4, 0, 0.2, 1) 1; }
/* Agrupadores */
.day-topics {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 100vw;
  padding: 0.3125rem 0.9375rem;
  background-color: #F2F2F2;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  scrollbar-width: none; }
  .day-topics::-webkit-scrollbar {
    display: none; }
  .day-topics__grouper, .day-topics__obj-title {
    font-size: 0.75em;
    margin-right: 0.75rem;
    white-space: nowrap; }
  .day-topics__grouper {
    font-weight: 500;
    margin-right: 1.5625rem;
    color: #424242; }
    .day-topics__grouper:last-of-type {
      margin-right: 0; }
  .day-topics__obj-title {
    color: #828282; }

/* Navegación Extendida */
.hidden-nav {
  position: fixed;
  z-index: 10;
  top: 0;
  right: auto;
  bottom: 0;
  left: -100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 80vw;
  height: 100vh;
  padding: 1.25rem 0;
  background-color: #FFF;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  scrollbar-width: none; }
  .hidden-nav::-webkit-scrollbar {
    display: none; }
  .hidden-nav__close-menu {
    position: fixed;
    z-index: 9;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1); }
  .hidden-nav__brand-logo {
    display: flex;
    align-items: center;
    margin-bottom: 1.5625rem;
    padding-right: 1.25rem;
    padding-left: 2.1875rem; }
    .hidden-nav__brand-logo img {
      max-width: 4.52625rem;
      height: auto; }
  .hidden-nav__close-menu-x {
    margin-left: auto; }
    .hidden-nav__close-menu-x i[class^='icon'] {
      min-width: 0.875rem;
      min-height: 0.875rem; }
  .hidden-nav__menu-item {
    font-family: "Work Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 0.875em;
    font-weight: 600;
    line-height: 2.25rem;
    cursor: pointer;
    text-transform: uppercase;
    color: #424242;
    transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
    .hidden-nav__menu-item > a:link,
    .hidden-nav__menu-item > a:visited,
    .hidden-nav__menu-item > a:active {
      line-height: 2.3125rem;
      display: block;
      padding: 0 1.5625rem;
      border-bottom: 1px solid #F2F2F2;
      background-color: #FFF; }
    .hidden-nav__menu-item--with-submenu {
      background-color: #FFF; }
      .hidden-nav__menu-item--with-submenu .hidden-nav__submenu-toggler {
        line-height: 2.3125rem;
        position: relative;
        display: block;
        padding: 0 1.5625rem;
        cursor: pointer;
        border-bottom: 1px solid #F2F2F2;
        background-color: #FFF;
        transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
        .hidden-nav__menu-item--with-submenu .hidden-nav__submenu-toggler > a:link,
        .hidden-nav__menu-item--with-submenu .hidden-nav__submenu-toggler > a:visited,
        .hidden-nav__menu-item--with-submenu .hidden-nav__submenu-toggler > a:active {
          display: inline-flex;
          padding: 0;
          border-bottom: 0 none;
          background-color: transparent; }
        .hidden-nav__menu-item--with-submenu .hidden-nav__submenu-toggler::after {
          position: absolute;
          top: 50%;
          right: 1.75rem;
          bottom: auto;
          left: auto;
          display: inline-flex;
          width: 0.75rem;
          height: 0.375rem;
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.898.562 11.383.09c-.135-.119-.352-.119-.461 0L6.014 4.373 1.078.089C.969-.03.753-.03.617.089L.102.562c-.136.095-.136.284 0 .402l5.667 4.947a.344.344 0 0 0 .462 0L11.898.964c.136-.118.136-.307 0-.402Z' fill='%23424242'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center;
          background-size: auto 100%;
          transform: translate3d(0, -50%, 0) rotate(0);
          backface-visibility: hidden;
          transform-style: preserve-3d;
          transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
      .hidden-nav__menu-item--with-submenu input[name='submenu-toggler'] {
        display: none; }

@keyframes growHeight {
  0% {
    height: 0; }
  100% {
    height: 100%; } }
        .hidden-nav__menu-item--with-submenu input[name='submenu-toggler']:checked ~ .hidden-nav__submenu {
          height: auto;
          animation: growHeight 0.2s cubic-bezier(0.4, 0, 0.2, 1) forwards; }
        .hidden-nav__menu-item--with-submenu input[name='submenu-toggler']:checked ~ .hidden-nav__submenu-toggler {
          border-color: #0994DC; }
          .hidden-nav__menu-item--with-submenu input[name='submenu-toggler']:checked ~ .hidden-nav__submenu-toggler::after {
            transform: translate3d(0, -50%, 0) rotate(-180deg);
            backface-visibility: hidden;
            transform-style: preserve-3d; }
  .hidden-nav__submenu {
    overflow: hidden;
    height: 0;
    transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  .hidden-nav__submenu-item {
    font-weight: 400;
    text-transform: capitalize; }
    .hidden-nav__submenu-item > a:link,
    .hidden-nav__submenu-item > a:visited,
    .hidden-nav__submenu-item > a:active {
      line-height: 2.25rem;
      display: block;
      padding: 0 1.5625rem;
      border-bottom: 1px solid #E0E0E0;
      background-color: #FFF;
      transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); }

.main-header__hidden-nav #menuToggler:checked ~ .hidden-nav {
  left: 0; }

.main-header__hidden-nav #menuToggler:checked ~ .hidden-nav__close-menu {
  display: block;
  background-color: rgba(52, 52, 52, 0.4);
  animation: fadeIn 0.2s cubic-bezier(0.4, 0, 0.2, 1); }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Adjuntos */
.main-header__middle .brand-logo {
  margin: 0 auto; }
  .main-header__middle .brand-logo img {
    max-width: 7.5625rem;
    height: auto; }

/* General */
.user {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: auto; }
  .user__profile-link {
    display: flex;
    align-items: center;
    flex-direction: row; }
    .user__profile-link i[class^='icon--user'] {
      min-width: 1.05rem;
      min-height: 1.2rem; }
  .user__user-actions-wrapper {
    position: absolute;
    z-index: 2;
    top: 100%;
    right: 0;
    bottom: auto;
    left: auto;
    display: none;
    flex-direction: column;
    background-color: #F2F2F2;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem; }
    .user__user-actions-wrapper [class^='user'] {
      font-size: 0.625em;
      font-weight: 500;
      line-height: 2.25rem;
      padding: 0 1.875rem;
      text-transform: uppercase;
      color: #424242; }
  .user #userActionToggler:checked ~ .user__user-actions-wrapper {
    display: flex; }

.economic-indicators {
  font-size: 0.75em;
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: 1.75rem;
  white-space: nowrap;
  color: #F2F2F2;
  background-color: #424242;
  /* &__buy-value {
        display: none;
    } */ }
  .economic-indicators__indicators-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    max-width: calc(100% - 3.125rem);
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    scrollbar-width: none; }
    .economic-indicators__indicators-wrapper::-webkit-scrollbar {
      display: none; }
  .economic-indicators__indicators-wrapper, .economic-indicators__value-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap; }
  .economic-indicators__name {
    font-weight: 500;
    padding-right: 0.3125rem; }
  .economic-indicators__sell-value, .economic-indicators__buy-value {
    margin-right: 0.3125rem; }
  .economic-indicators__arrow {
    display: none;
    margin-right: 0.3125rem; }
  .economic-indicators__fluctuation {
    color: #0994DC; }
  .economic-indicators__value-wrapper {
    margin-right: 0.9375rem;
    line-height: 1.4em; }
    .economic-indicators__value-wrapper:first-of-type {
      padding-left: 0.9375rem; }
    .economic-indicators__value-wrapper:last-of-type {
      margin-right: 0; }
    .economic-indicators__value-wrapper--up .economic-indicators__fluctuation {
      color: #27AE60; }
    .economic-indicators__value-wrapper--up .economic-indicators__arrow {
      width: 0;
      height: 0;
      border-width: 0 0.4375rem 0.5625rem 0.4375rem;
      border-style: inset;
      border-style: solid;
      border-color: transparent transparent #27AE60 transparent;
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg); }
    .economic-indicators__value-wrapper--down .economic-indicators__fluctuation {
      color: #EB5757; }
    .economic-indicators__value-wrapper--down .economic-indicators__arrow {
      width: 0;
      height: 0;
      border-width: 0.5625rem 0.4375rem 0 0.4375rem;
      border-style: inset;
      border-style: solid;
      border-color: #EB5757 transparent transparent transparent;
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg); }
  .economic-indicators__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 3.125rem;
    max-width: 3.125rem;
    margin-left: auto; }

/* Notas */
.urgent-news {
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  box-sizing: border-box;
  max-width: 100%;
  min-height: 2.875rem;
  padding-right: 0.9375rem;
  background-color: #D90D0D; }
  .urgent-news i[class^='icon--alert'] {
    min-width: 1.1475rem;
    min-height: 1.02rem; }
  .urgent-news__alert-icon {
    display: inline-flex;
    align-items: center;
    align-self: stretch;
    flex: 0 0 2.8125rem;
    min-height: 100%;
    padding: 1.25rem 0;
    text-transform: uppercase;
    background-color: #000;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23fff' d='M10.508 4.65a1.5 1.5 0 1 1 2.984 0l-.58 9.525a.916.916 0 0 1-1.823 0l-.581-9.526ZM10.5 18a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.5rem; }
  .urgent-news__badge {
    font-weight: 500;
    margin-right: 0.3125rem;
    text-transform: uppercase; }
    .urgent-news__badge::after {
      content: ':'; }
  .urgent-news__info-wrapper, .urgent-news__link {
    display: flex;
    align-items: center;
    flex-direction: row;
    color: #FFF; }
  .urgent-news__info-wrapper {
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    min-height: inherit;
    gap: 0 0.625rem; }
  .urgent-news__close-alert {
    position: relative;
    align-self: stretch;
    min-width: 1.25rem;
    margin-left: auto;
    cursor: pointer; }
  .urgent-news__close-alert-bar {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    display: block;
    width: 1.25rem;
    height: 0.09375rem;
    transform-origin: center;
    background-color: #FFF;
    transform: translate3d(-50%, -50%, 0);
    backface-visibility: hidden;
    transform-style: preserve-3d;
    border-radius: 0.09375rem; }
    .urgent-news__close-alert-bar:nth-child(1) {
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
      backface-visibility: hidden;
      transform-style: preserve-3d; }
    .urgent-news__close-alert-bar:nth-child(2) {
      transform: translate3d(-50%, -50%, 0) rotate(-45deg);
      backface-visibility: hidden;
      transform-style: preserve-3d; }
  .urgent-news__title {
    font-family: "Work Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.4em;
    letter-spacing: 0.25px;
    color: #FFF; }

[id^='alertToggler']:checked + .urgent-news {
  display: none; }

/********** [-end- HEADER STYLES -end-] **********/
/********** [ -start- MAIN CONTENT STYLES -start- ] **********/
/* Templates */
.widget {
  margin-bottom: 0.9375rem; }

/* Banners */
.banner {
  position: relative;
  display: flex;
  margin-bottom: 0.9375rem;
  padding: 0.625rem 0;
  background-color: #E0E0E0; }
  .banner img {
    max-width: 100%;
    height: auto; }
  .banner:not(.banner--no-background):not(.banner--free-banner) {
    padding-top: 1.25rem;
    padding-bottom: 0.625rem; }
    .banner:not(.banner--no-background):not(.banner--free-banner)::before {
      font-size: 0.6875em;
      line-height: 1.2em;
      position: absolute;
      top: calc((1.25rem - 1.2em) / 2);
      left: 50%;
      content: 'Publicidad';
      text-transform: uppercase;
      color: rgba(66, 66, 66, 0.5);
      transform: translate3d(-50%, 0, 0);
      backface-visibility: hidden;
      transform-style: preserve-3d; }
  .banner--free-banner {
    padding: 0;
    background-color: transparent;
    /* DINDALO-878 -  2023 - Revisar estilo banner libre */ }
    .banner--free-banner.cripto {
      flex-direction: column; }
      .banner--free-banner.cripto .banner__footer {
        font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
        display: flex;
        justify-content: center;
        padding-top: 0.3125rem;
        padding-bottom: 0.625rem;
        color: aliceblue;
        background-color: #424242; }
        .banner--free-banner.cripto .banner__footer span {
          color: #0994DC; }
    .banner--free-banner .banner__content-wrapper {
      display: flex;
      align-items: center;
      flex: 1 0 100%;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      margin: 0 auto;
      text-align: center; }
      .banner--free-banner .banner__content-wrapper > *,
      .banner--free-banner .banner__content-wrapper iframe {
        flex-basis: 100%;
        flex-grow: 1;
        flex-shrink: 0;
        max-width: 100%;
        margin: 0 auto;
        text-align: center; }
        .banner--free-banner .banner__content-wrapper > * img,
        .banner--free-banner .banner__content-wrapper iframe img {
          max-width: 100%;
          height: auto; }
      .banner--free-banner .banner__content-wrapper > img {
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 1;
        max-width: 100%;
        height: auto;
        -o-object-fit: cover;
           object-fit: cover; }
  .banner--align-center {
    justify-content: center;
    text-align: center; }

.header-ad {
  padding: 0.3125rem 0; }
  .header-ad .banner {
    width: -moz-fit-content;
    width: fit-content;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    padding: 0; }
  .header-ad:empty {
    display: none; }
/* Notas */
.news-article {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  margin-bottom: 0.9375rem;
  background-color: #FFF;
  /* Video or Audio */
  /* notas para suscriptores */ }
  .news-article__title, .news-article__preview {
    line-height: 1.2em;
    color: #424242; }
  .news-article__journalist {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-size: 0.875em;
    font-weight: 500;
    line-height: 1;
    display: block;
    margin-top: auto;
    padding-top: 0.625rem;
    color: #828282; }
  .news-article__badge {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-size: 0.875em;
    font-weight: 600;
    line-height: 1.4em;
    display: inline-flex;
    width: -moz-fit-content;
    width: fit-content;
    color: #069; }
    .news-article__badge--lbp {
      position: relative;
      padding-left: 1.25rem;
      color: #EB5757; }
      .news-article__badge--lbp::before {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 0;
        display: inline-flex;
        width: 0.625rem;
        height: 0.625rem;
        content: '';
        background-color: #EB5757;
        border-radius: 100%;
        transform: translate3d(0, -50%, 0);
        backface-visibility: hidden;
        transform-style: preserve-3d; }
  .news-article__title {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-weight: 700;
    line-height: 1.3em; }
  .news-article__figure {
    position: relative; }
    .news-article__figure img {
      width: 100%;
      max-width: 100%;
      height: auto;
      vertical-align: bottom; }
  .news-article:not([class*='landscape']) .news-article__info-wrapper, .news-article:not([class*='landscape']) .news-article__article-wrapper {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column; }
  .news-article__media-badge--video, .news-article__media-badge--audio {
    position: absolute;
    z-index: 1;
    display: inline-flex;
    background-repeat: no-repeat; }
  .news-article__media-badge--video {
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    width: 3.5rem;
    height: 3.5rem;
    background-image: url("data:image/svg+xml,%3Csvg width='70' height='70' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23a)'%3E%3Cpath d='M49.875 35 27.562 47.882V22.118L49.876 35Z' fill='%23fff'/%3E%3Crect x='8.75' y='8.75' width='52.5' height='52.5' rx='26.25' stroke='%23fff' stroke-width='3.5' shape-rendering='crispEdges'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='a' x='0' y='0' width='70' height='70' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset/%3E%3CfeGaussianBlur stdDeviation='3.5'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix values='0 0 0 0 0.729167 0 0 0 0 0.729167 0 0 0 0 0.729167 0 0 0 0.25 0'/%3E%3CfeBlend in2='BackgroundImageFix' result='effect1_dropShadow_1089_25979'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow_1089_25979' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
    background-position: center;
    background-size: 100% auto;
    filter: drop-shadow(0px 0px 7px rgba(186, 186, 186, 0.25));
    transform: translate3d(-50%, -50%, 0);
    backface-visibility: hidden;
    transform-style: preserve-3d; }
  .news-article__media-badge--audio {
    font-size: 0.75em;
    line-height: 1.5625rem;
    top: auto;
    right: auto;
    bottom: 0.625rem;
    left: 0.625rem;
    align-content: center;
    align-items: center;
    min-height: 1.5625rem;
    padding: 0 0.4375rem 0 1.5625rem;
    vertical-align: middle;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(0, 0, 0, 0.7);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.01' height='14.234'%3E%3Cpath data-name='Icon material-volume-down' d='M12.01 7.117a4 4 0 00-2.224-3.585v7.162a3.98 3.98 0 002.224-3.576zM0 4.448v5.338h3.559l4.448 4.448V0L3.559 4.448z' fill='%23fff' opacity='.8'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 0.4375rem center;
    background-size: 0.75062rem 0.88938rem;
    border-radius: 0.25rem; }
  .news-article--border-bottom {
    position: relative; }
    .news-article--border-bottom::after {
      position: absolute;
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      display: block;
      width: 50%;
      height: 0.125rem;
      content: '';
      background-color: #E0E0E0; }
  .news-article__subscribers-only-tag {
    position: absolute;
    z-index: 1;
    top: 1.25rem;
    right: 0;
    bottom: auto;
    left: auto;
    display: inline-flex;
    overflow: hidden;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    box-sizing: border-box;
    width: 1.875rem;
    min-height: 1.5625rem;
    padding: 0 0.3125rem;
    background-color: #069;
    border-top-left-radius: 6.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 6.25rem; }
    .news-article__subscribers-only-tag-text {
      font-size: 0.625em;
      font-weight: 500;
      line-height: 1;
      display: none;
      padding-left: 0.3125rem;
      text-transform: uppercase;
      color: #FFF; }
    .news-article__subscribers-only-tag-icon {
      margin-left: auto; }
      .news-article__subscribers-only-tag-icon[class*='icon'] {
        min-width: 1.125rem;
        min-height: 1.125rem; }
  .news-article__info-wrapper .news-article__subscribers-only-tag {
    position: static;
    margin-bottom: 0.3125rem;
    margin-left: auto; }

.news-article--medium-highlighted-news {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  .news-article--medium-highlighted-news .news-article__badge {
    font-size: 0.875em;
    margin-bottom: 0.3125rem; }
  .news-article--medium-highlighted-news .news-article__info-wrapper {
    padding: 0.625rem 0.625rem 0.9375rem; }
  .news-article--medium-highlighted-news .news-article__journalist {
    padding-top: 0.625rem; }
  .news-article--medium-highlighted-news .news-article__title {
    font-size: 1.5em; }
  .news-article--medium-highlighted-news .news-article__figure {
    position: relative;
    display: block;
    overflow: hidden; }
    .news-article--medium-highlighted-news .news-article__figure:before {
      display: block;
      width: 100%;
      padding-top: 56%;
      content: ''; }
    .news-article--medium-highlighted-news .news-article__figure > img,
    .news-article--medium-highlighted-news .news-article__figure [class*='img'] {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: auto;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: auto;
      transform: translate3d(0, -50%, 0);
      backface-visibility: hidden;
      transform-style: preserve-3d; }
  .news-article--medium-highlighted-news .news-article__preview {
    font-size: 1em;
    line-height: 1.4em;
    padding-top: 0.3125rem;
    color: #424242; }
  .news-article--medium-highlighted-news::after {
    left: 0.625rem; }

.news-article {
  /* Notas chicas */ }
  .news-article--simple-news {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column; }
    .news-article--simple-news .news-article__badge {
      font-size: 0.875em;
      margin-bottom: 0.625rem; }
    .news-article--simple-news .news-article__figure {
      position: relative;
      display: block;
      overflow: hidden; }
      .news-article--simple-news .news-article__figure:before {
        display: block;
        width: 100%;
        padding-top: 56%;
        content: ''; }
      .news-article--simple-news .news-article__figure > img,
      .news-article--simple-news .news-article__figure [class*='img'] {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: auto;
        left: 0;
        width: 100%;
        max-width: 100%;
        height: auto;
        transform: translate3d(0, -50%, 0);
        backface-visibility: hidden;
        transform-style: preserve-3d; }
      .news-article--simple-news .news-article__figure ~ .news-article__info-wrapper {
        padding: 0.625rem 0 0.9375rem; }
        .news-article--simple-news .news-article__figure ~ .news-article__info-wrapper .news-article__title {
          font-size: 1.5em; }
    .news-article--simple-news .news-article__info-wrapper {
      padding: 0 0 0.9375rem; }
    .news-article--simple-news .news-article__journalist {
      font-size: 0.875em;
      padding-top: 0.9375rem; }
    .news-article--simple-news .news-article__title {
      font-size: 1.25em; }
    .news-article--simple-news .news-article__preview {
      padding-top: 0.9375rem; }
    .news-article--simple-news ~ .news-article--no-image .news-article__title {
      font-size: 1.25em; }
  .news-article--simple-news-xs .news-article__badge {
    font-size: 0.75em;
    margin-bottom: 0.3125rem; }
    .news-article--simple-news-xs .news-article__badge--lbp {
      padding-left: 0.78125rem; }
      .news-article--simple-news-xs .news-article__badge--lbp::before {
        width: 0.46875rem;
        height: 0.46875rem; }
  .news-article--simple-news-xs .news-article__figure ~ .news-article__info-wrapper {
    padding: 0.3125rem 0 0.625rem; }
  .news-article--simple-news-xs .news-article__info-wrapper {
    padding: 0 0 0.625rem; }
  .news-article--simple-news-xs .news-article__journalist {
    font-size: 0.75em;
    padding-top: 0.625rem; }
  .news-article--simple-news-xs .news-article__title {
    font-size: 1em; }
  .news-article--simple-news-xs .news-article__preview {
    font-size: 0.75em;
    padding-top: 0.625rem; }

/* Notas Sin Imágenes y con borde a la izquierda */
.news-article-wrapper--with-border-left {
  flex-grow: 1;
  padding-left: 1.25rem;
  border-left: 0.125rem solid #E0E0E0; }
  .news-article-wrapper--with-border-left .news-article--simple-news {
    flex: 0 0 auto; }
    .news-article-wrapper--with-border-left .news-article--simple-news .news-article__journalist {
      margin-top: 0; }

.top-ranked-news {
  margin-right: -0.625rem;
  margin-bottom: 1.875rem;
  margin-left: -0.625rem;
  padding: 0.75rem 0.9375rem;
  counter-reset: ranking;
  background-color: #F2F2F2; }
  .top-ranked-news__header {
    margin-bottom: 1.25rem; }
  .top-ranked-news__news-article {
    position: relative;
    margin-bottom: 0.75rem;
    padding-left: 3.25rem; }
    .top-ranked-news__news-article:last-of-type {
      margin-bottom: 0; }
    .top-ranked-news__news-article::before {
      font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
      font-size: 1.25em;
      font-weight: 700;
      line-height: 2.5rem;
      position: absolute;
      top: 0;
      left: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
      height: 2.5rem;
      content: counter(ranking);
      counter-increment: ranking;
      text-align: center;
      color: #0994DC;
      background-color: rgba(0, 136, 205, 0.1);
      border-radius: 100%; }
  .top-ranked-news__obj-title, .top-ranked-news__news-article-title {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-weight: 700; }
  .top-ranked-news__obj-title {
    font-size: 1.75em;
    color: #069; }
  .top-ranked-news__news-article-title {
    font-size: 1.25em;
    line-height: 1.2em;
    color: #121212; }

.news-article--xs-news-square-image {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap; }
  .news-article--xs-news-square-image .news-article__badge {
    font-size: 0.75em;
    margin-bottom: 0.3125rem; }
    .news-article--xs-news-square-image .news-article__badge--lbp {
      padding-left: 0.78125rem; }
      .news-article--xs-news-square-image .news-article__badge--lbp::before {
        width: 0.46875rem;
        height: 0.46875rem; }
  .news-article--xs-news-square-image .news-article__figure {
    flex: 0 0 9.6875rem;
    max-width: 9.6875rem;
    position: relative;
    display: block;
    overflow: hidden;
    width: 9.6875rem;
    height: 9.6875rem; }
    .news-article--xs-news-square-image .news-article__figure:before {
      display: block;
      width: 9.6875rem;
      padding-top: 9.6875rem;
      content: ''; }
    .news-article--xs-news-square-image .news-article__figure > a:first-of-type,
    .news-article--xs-news-square-image .news-article__figure iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    .news-article--xs-news-square-image .news-article__figure iframe {
      width: inherit;
      height: inherit; }
    .news-article--xs-news-square-image .news-article__figure img {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      width: 100%;
      height: auto;
      transform: translate3d(0, -50%, 0);
      backface-visibility: hidden;
      transform-style: preserve-3d; }
    .news-article--xs-news-square-image .news-article__figure + .news-article__info-wrapper {
      flex: 0 0 calc(100% - 10.625rem);
      max-width: calc(100% - 10.625rem);
      padding-left: 0.9375rem; }
  .news-article--xs-news-square-image .news-article__info-wrapper {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    padding: 0; }
  .news-article--xs-news-square-image .news-article__journalist {
    font-size: 0.75em;
    margin-top: 0;
    padding-top: 0.625rem; }
  .news-article--xs-news-square-image .news-article__title {
    font-size: 1em;
    flex: 0 0 auto;
    min-width: 0;
    white-space: normal; }
    .news-article--xs-news-square-image .news-article__title a {
      white-space: normal; }
  .news-article--xs-news-square-image .news-article__preview {
    font-size: 0.75em;
    padding-top: 0.625rem; }

.news-article--xs-news-small-image .news-article__figure {
  flex: 0 0 6.25rem;
  max-width: 6.25rem;
  position: relative;
  display: block;
  overflow: hidden;
  width: 6.25rem;
  height: 6.25rem; }
  .news-article--xs-news-small-image .news-article__figure:before {
    display: block;
    width: 6.25rem;
    padding-top: 6.25rem;
    content: ''; }
  .news-article--xs-news-small-image .news-article__figure > a:first-of-type,
  .news-article--xs-news-small-image .news-article__figure iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .news-article--xs-news-small-image .news-article__figure iframe {
    width: inherit;
    height: inherit; }
  .news-article--xs-news-small-image .news-article__figure img {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    width: 100%;
    height: auto;
    transform: translate3d(0, -50%, 0);
    backface-visibility: hidden;
    transform-style: preserve-3d; }
  .news-article--xs-news-small-image .news-article__figure + .news-article__info-wrapper {
    flex: 0 0 calc(100% - 7.1875rem);
    max-width: calc(100% - 7.1875rem);
    padding-left: 0.9375rem; }

.news-article--xs-news-small-image .news-article__title {
  font-size: 1.25em; }

.last-news {
  margin-bottom: 0.9375rem;
  padding: 0.75rem;
  background-color: #F2F2F2; }
  .last-news__article {
    position: relative;
    margin-bottom: 1.25rem;
    padding-bottom: 0.75rem; }
    .last-news__article::after {
      position: absolute;
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      display: block;
      width: 55%;
      height: 0.125rem;
      content: '';
      background-color: #069; }
    .last-news__article:last-of-type {
      margin-bottom: 0; }
  .last-news__article-title {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1.2em;
    color: #121212;
    transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
    .last-news__article-title:hover {
      color: #828282; }
  .last-news__obj-title {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.75em;
    font-weight: 700;
    margin-bottom: 1.25rem;
    color: #069; }

/* Cabezales */
.standard-header {
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 0.9375rem;
  padding-bottom: 0.3125rem;
  border-bottom: 0.1875rem solid #069; }
  .standard-header__title {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.75em;
    font-weight: 700;
    line-height: 1.2em;
    color: #069; }
  .standard-header__related-banner {
    margin-bottom: -0.5rem; }
    .standard-header__related-banner img {
      max-width: 15.625rem;
      height: auto;
      vertical-align: bottom; }

/* Destalle Sección */
.news-article--landscape-news:not([class*='square']) {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 1.25rem; }
  .news-article--landscape-news:not([class*='square']) .news-article__badge {
    font-size: 0.875em;
    margin-bottom: 0.3125rem; }
  .news-article--landscape-news:not([class*='square']) .news-article__figure {
    flex: 0 0 6.25rem;
    max-width: 6.25rem;
    position: relative;
    display: block;
    overflow: hidden;
    width: 100px;
    height: 100px; }
    .news-article--landscape-news:not([class*='square']) .news-article__figure:before {
      display: block;
      width: 100px;
      padding-top: 100px;
      content: ''; }
    .news-article--landscape-news:not([class*='square']) .news-article__figure > a:first-of-type,
    .news-article--landscape-news:not([class*='square']) .news-article__figure iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    .news-article--landscape-news:not([class*='square']) .news-article__figure iframe {
      width: inherit;
      height: inherit; }
    .news-article--landscape-news:not([class*='square']) .news-article__figure img {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      width: 100%;
      height: auto;
      transform: translate3d(0, -50%, 0);
      backface-visibility: hidden;
      transform-style: preserve-3d; }
    .news-article--landscape-news:not([class*='square']) .news-article__figure + .news-article__info-wrapper {
      flex: 0 0 calc(100% - 7.1875rem);
      max-width: calc(100% - 7.1875rem);
      padding-bottom: 0;
      padding-left: 0.9375rem; }
  .news-article--landscape-news:not([class*='square']) .news-article__info-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 0.9375rem; }
  .news-article--landscape-news:not([class*='square']) .news-article__journalist {
    font-size: 0.875em;
    padding-top: 0.9375rem; }
  .news-article--landscape-news:not([class*='square']) .news-article__title {
    font-size: 1.25em; }

.section-header {
  margin-bottom: 0.9375rem; }
  .section-header__pages-list {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-size: 0.875em;
    font-weight: 500;
    margin-bottom: 0.9375rem;
    color: #069; }
  .section-header__pages-list-item {
    display: inline-flex;
    align-items: center; }
    .section-header__pages-list-item::after {
      display: inline-flex;
      align-items: center;
      width: 0.25rem;
      height: 0.25rem;
      margin: 0 0.625rem;
      content: '';
      background-color: #069;
      border-radius: 100%; }
    .section-header__pages-list-item:last-of-type::after {
      display: none; }
  .section-header__theme-item {
    margin-right: 0.9375rem;
    color: #069;
    transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
    .section-header__theme-item:hover {
      color: #0994DC; }
  .section-header__themes-list {
    font-weight: 500; }
  .section-header__themes-list-title {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-weight: 700;
    margin-right: 0.9375rem;
    color: #121212; }
  .section-header__title {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.75em;
    font-weight: 700;
    margin-bottom: 0.9375rem;
    color: #069; }
  .section-header__pages-list, .section-header__themes-list {
    display: flex;
    align-items: center; }

/* Edición impresa */
body.printed-edition .section-header__title {
  margin-bottom: 0; }

body.printed-edition .section-header__themes-list, body.printed-edition .section-header__pages-list {
  display: none; }

/* Global */
.pagination {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  color: #121212; }
  .pagination__arrow {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.9375rem;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.562.102.09.617c-.119.136-.119.352 0 .461l4.284 4.908-4.284 4.936c-.119.108-.119.325 0 .461l.473.515c.095.136.284.136.402 0l4.947-5.667a.344.344 0 0 0 0-.462L.964.102C.846-.034.657-.034.562.102Z' fill='%23424242'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.375rem 0.75rem;
    transition: 0.2 cubic-bezier(0.4, 0, 0.2, 1); }
    .pagination__arrow--left {
      transform: rotate(180deg);
      backface-visibility: hidden;
      transform-style: preserve-3d; }
    .pagination__arrow:hover {
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.562.102.09.617c-.119.136-.119.352 0 .461l4.284 4.908-4.284 4.936c-.119.108-.119.325 0 .461l.473.515c.095.136.284.136.402 0l4.947-5.667a.344.344 0 0 0 0-.462L.964.102C.846-.034.657-.034.562.102Z' fill='%230994DC'/%3E%3C/svg%3E"); }
  .pagination__number {
    font-size: 0.875em;
    line-height: 1.4em;
    margin-right: 0.9375rem;
    transition: 0.2 cubic-bezier(0.4, 0, 0.2, 1); }
    .pagination__number:hover {
      color: #0994DC !important; }
    .pagination__number--selected {
      font-weight: 500;
      color: #069 !important;
      /* &::after {
                padding-left: $air-15;

                content: '...';
                }*/ }

/* Indicadores */
.head-and-description {
  margin-bottom: 0.9375rem; }
  .head-and-description__subtitle {
    font-size: 1.25em;
    line-height: 1.4em;
    color: #121212; }
  .head-and-description__title {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.75em;
    font-weight: 700;
    line-height: 1.2em;
    margin-bottom: 0.9375rem;
    color: #069; }
.variation-max-min {
  margin-bottom: 1.25rem;
  padding: 0.9375rem;
  color: #F2F2F2;
  background-color: #069;
  border-radius: 0.25rem; }
  .variation-max-min__date-time {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-size: 0.875em;
    font-weight: 500;
    line-height: 1.4em;
    display: block;
    min-height: 1.4em;
    margin-bottom: 0.9375rem; }
  .variation-max-min__value, .variation-max-min__percent {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.2em;
    min-height: 1.2em; }
  .variation-max-min__percent {
    position: relative;
    padding-left: 1.25rem; }
    .variation-max-min__percent::before {
      position: absolute;
      top: 50%;
      left: 0;
      display: inline-block;
      width: 0.75rem;
      height: 0.875rem;
      content: '';
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 100%;
      transform: translate3d(0, -50%, 0);
      backface-visibility: hidden;
      transform-style: preserve-3d; }
    .variation-max-min__percent.up {
      color: #27AE60; }
      .variation-max-min__percent.up::before {
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.458 14.5c.39 0 .72-.313.72-.75V7.496h3.096c.661 0 .962-.782.511-1.282L6.525.711c-.3-.281-.75-.281-1.02 0L.214 6.214c-.45.5-.15 1.282.51 1.282h3.126v6.254c0 .437.3.75.721.75h2.886Z' fill='%2327AE60'/%3E%3C/svg%3E"); }
    .variation-max-min__percent.down {
      transform-origin: center;
      color: #EB5757; }
      .variation-max-min__percent.down::before {
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.458 14.5c.39 0 .72-.313.72-.75V7.496h3.096c.661 0 .962-.782.511-1.282L6.525.711c-.3-.281-.75-.281-1.02 0L.214 6.214c-.45.5-.15 1.282.51 1.282h3.126v6.254c0 .437.3.75.721.75h2.886Z' fill='%23EB5757'/%3E%3C/svg%3E");
        transform: translate3d(0, -50%, 0) rotate(180deg);
        backface-visibility: hidden;
        transform-style: preserve-3d; }
    .variation-max-min__percent.equal {
      color: #0994DC; }
      .variation-max-min__percent.equal::before {
        width: 1rem;
        height: 0.625rem;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.857 6.667H1.143C.5 6.667 0 7.187 0 7.777V8.89C0 9.514.5 10 1.143 10h13.714C15.464 10 16 9.514 16 8.889V7.778c0-.59-.536-1.111-1.143-1.111Zm0-6.667H1.143C.5 0 0 .52 0 1.111v1.111c0 .625.5 1.111 1.143 1.111h13.714c.607 0 1.143-.486 1.143-1.11V1.11C16 .521 15.464 0 14.857 0Z' fill='%230994DC'/%3E%3C/svg%3E"); }
  .variation-max-min__values-wrapper {
    display: flex;
    align-items: center; }
  .variation-max-min__percent-content, .variation-max-min__value-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 1.5625rem; }
    .variation-max-min__percent-content:last-of-type, .variation-max-min__value-wrapper:last-of-type {
      margin-right: 0; }
  .variation-max-min__description, .variation-max-min__source {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-size: 0.75em;
    font-weight: 500;
    line-height: 1.4em;
    text-transform: uppercase; }
  .variation-max-min__source {
    margin-top: 0.3125rem; }
    .variation-max-min__source a {
      text-decoration: underline; }
  .variation-max-min--xs {
    color: #121212;
    border: 1px solid #F2F2F2;
    background-color: #FFF; }
    .variation-max-min--xs__date-time {
      font-family: #424242; }
    .variation-max-min--xs .variation-max-min__date-time, .variation-max-min--xs .variation-max-min__percent {
      margin-left: auto; }
    .variation-max-min--xs .variation-max-min__title {
      font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
      font-size: 1em;
      font-weight: 700;
      color: #069; }
      .variation-max-min--xs .variation-max-min__title i[class*='icon'] {
        min-width: 0.8rem;
        min-height: 0.8rem;
        margin-left: 0.3125rem; }
      .variation-max-min--xs .variation-max-min__title a {
        display: inline-flex;
        align-items: center; }
    .variation-max-min--xs .variation-max-min__values-wrapper {
      align-items: flex-end; }
      .variation-max-min--xs .variation-max-min__values-wrapper:first-of-type {
        padding-bottom: 0.9375rem; }
    .variation-max-min--xs .variation-max-min__percent {
      font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
      font-size: 1.25em;
      font-weight: 700;
      padding-left: 1.5625rem; }
      .variation-max-min--xs .variation-max-min__percent:before {
        width: 1rem;
        height: 1.125rem; }
      .variation-max-min--xs .variation-max-min__percent.equal:before {
        width: 1rem;
        height: 0.625rem; }
    .variation-max-min--xs .variation-max-min__value {
      font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
      font-size: 1em;
      font-weight: 700;
      line-height: 1.2;
      color: #121212; }
    .variation-max-min--xs .variation-max-min__description {
      font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
      font-size: 0.75em;
      font-weight: 600;
      line-height: 1.4;
      text-transform: uppercase;
      color: #828282; }
      .variation-max-min--xs .variation-max-min__description a {
        text-decoration: underline; }
    .variation-max-min--xs .variation-max-min__date-time {
      font-size: 0.75em;
      font-weight: 400;
      margin-top: auto;
      margin-bottom: 0;
      color: #828282; }
.historical {
  font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
  margin-bottom: 1.25rem;
  padding: 0.9375rem;
  border: 1px solid #F2F2F2;
  background-color: #FFF;
  border-radius: 0.25rem;
  /* Histórico Grande DINDALO-927 */ }
  .historical__date {
    font-weight: 400; }
  .historical__description {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-size: 0.75em;
    font-weight: 500;
    line-height: 1.4em;
    text-transform: uppercase;
    color: #828282; }
  .historical__title {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-size: 0.875em;
    font-weight: 600;
    line-height: 1.4em;
    color: #069; }
  .historical__title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.9375rem; }
  .historical__value {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.2em;
    min-height: 1.2em;
    color: #121212; }
  .historical__value-wrapper {
    display: flex;
    flex-direction: column;
    padding-right: 1.5625rem; }
    .historical__value-wrapper:last-of-type {
      padding-right: 0; }
  .historical__view-more {
    font-size: 0.625em;
    line-height: 1.125rem;
    display: inline-flex;
    padding: 0 0.3125rem;
    text-transform: uppercase;
    color: #FFF !important;
    background-color: #069;
    border-radius: 0.125rem; }
  .historical__values-wrapper {
    display: flex;
    align-items: center; }
  .historical--big .historical__title {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-size: 2em;
    line-height: 1.2em; }
  .historical--big .historical__values-wrapper {
    align-items: flex-start;
    flex-direction: column; }
  .historical--big .historical__description {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.125em;
    line-height: 1.2em;
    margin-bottom: 0.625rem;
    text-transform: capitalize;
    color: #0994DC; }
  .historical--big .historical__value {
    font-size: 2em;
    line-height: 1.2em; }
  .historical--big .historical__value-wrapper {
    width: 100%;
    padding-right: 0; }
    .historical--big .historical__value-wrapper + .historical__value-wrapper {
      margin-top: 0.9375rem;
      padding-top: 0.9375rem;
      border-top: 1px solid #E0E0E0; }
  .historical--big .historical__sponsor-wrapper {
    padding-top: 0.9375rem; }
    .historical--big .historical__sponsor-wrapper img {
      max-width: 100%;
      height: auto; }
.indicators-chart {
  position: relative;
  margin-bottom: 1.25rem; }
  .indicators-chart > * {
    width: 100%; }
  .indicators-chart__chart {
    min-height: 415px; }
  .indicators-chart__chart-wrapper * {
    width: 100%;
    max-width: 100%;
    height: auto; }
  .indicators-chart__controls {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    max-width: 100%;
    margin-bottom: 0.9375rem;
    border-bottom: 1px solid #E0E0E0; }
    .indicators-chart__controls > img {
      position: absolute;
      z-index: 1;
      top: 1.875rem;
      right: 0; }
  .indicators-chart__period-btn {
    font-size: 0.875em;
    font-weight: 500;
    line-height: 1.4em;
    position: relative;
    margin-right: 2.5rem;
    color: #828282;
    transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
    .indicators-chart__period-btn:not(.active) {
      cursor: pointer; }
      .indicators-chart__period-btn:not(.active):hover {
        color: #069; }
    .indicators-chart__period-btn:last-of-type {
      margin-right: 0; }
    .indicators-chart__period-btn.active {
      color: #424242; }
      .indicators-chart__period-btn.active::after {
        position: absolute;
        z-index: 1;
        top: auto;
        right: 0;
        bottom: -1px;
        left: 0;
        display: block;
        height: 1px;
        content: '';
        background-color: #424242; }
.general-historical {
  margin-bottom: 0.9375rem; }
  .general-historical__chart-wrapper {
    margin-bottom: 1.5625rem; }
    .general-historical__chart-wrapper * {
      width: 100%;
      max-width: 100%;
      height: auto; }
  .general-historical__config {
    display: flex;
    flex: 0 0 calc(50% - 0.625rem);
    flex-direction: column;
    max-width: calc(50% - 0.625rem);
    margin-right: 1.25rem; }
    .general-historical__config:last-of-type {
      margin-right: 0; }
    .general-historical__config label {
      font-weight: 500;
      line-height: 1.4em;
      margin-bottom: 0.625rem;
      color: #069; }
  .general-historical__date-selector-wrapper {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 0.9375rem; }
  .general-historical__datepicker {
    font-family: "Work Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 1em;
    font-weight: 400;
    line-height: 2.5rem;
    padding: 0 0.3125rem;
    border: 1px solid #F2F2F2;
    background-color: #FFF;
    border-radius: 0.25rem; }
    .general-historical__datepicker::-moz-placeholder {
      font-family: "Work Sans", "Helvetica Neue", Arial, sans-serif;
      font-size: 1em;
      font-weight: 400;
      line-height: 2.5rem;
      color: #000; }
    .general-historical__datepicker::placeholder {
      font-family: "Work Sans", "Helvetica Neue", Arial, sans-serif;
      font-size: 1em;
      font-weight: 400;
      line-height: 2.5rem;
      color: #000; }
    .general-historical__datepicker:-moz-placeholder {
      font-family: "Work Sans", "Helvetica Neue", Arial, sans-serif;
      font-size: 1em;
      font-weight: 400;
      line-height: 2.5rem;
      color: #000; }
    .general-historical__datepicker::-webkit-input-placeholder {
      font-family: "Work Sans", "Helvetica Neue", Arial, sans-serif;
      font-size: 1em;
      font-weight: 400;
      line-height: 2.5rem;
      color: #000; }
    .general-historical__datepicker:-ms-input-placeholder {
      font-family: "Work Sans", "Helvetica Neue", Arial, sans-serif;
      font-size: 1em;
      font-weight: 400;
      line-height: 2.5rem;
      color: #000; }
  .general-historical__table {
    width: 100vw;
    margin-right: -0.625rem;
    margin-left: -0.625rem;
    table-layout: fixed; }
  .general-historical__tbody {
    font-size: 0.875em;
    line-height: 1.4em;
    color: #121212; }
    .general-historical__tbody td {
      width: 30%;
      padding: 0.3125rem 1.25rem; }
      .general-historical__tbody td:first-child {
        width: 40%; }
  .general-historical__thead {
    font-size: 0.875em;
    font-weight: 500;
    line-height: 1.4em;
    color: #121212;
    background-color: #F2F2F2; }
    .general-historical__thead th {
      width: 30%;
      padding: 0.3125rem 1.25rem;
      text-align: left; }
      .general-historical__thead th:first-child {
        width: 40%; }
  .general-historical__button {
    font-size: 0.875em;
    font-weight: 500;
    line-height: 2.5rem;
    margin-top: 0.9375rem;
    padding: 0 1.5625rem;
    cursor: pointer;
    color: #FFF;
    background-color: #069;
    border-radius: 0.125rem;
    transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
    .general-historical__button:hover {
      background-color: #0994DC; }
.markets-menu {
  display: flex;
  flex-wrap: wrap; }
  .markets-menu__description {
    font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.5em;
    font-weight: 700;
    color: #069; }
  .markets-menu__icon-wrapper {
    display: inline-flex;
    align-items: center;
    flex: 0 0 2rem;
    justify-content: center;
    max-width: 2rem;
    margin-right: 0.75rem; }
  .markets-menu__icon {
    width: auto;
    max-height: 1.625rem; }
  .markets-menu__link {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding: 0 0.9375rem; }
  .markets-menu__market-list-item {
    line-height: 4rem;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0.9375rem;
    border: 1px solid #F2F2F2;
    border-radius: 0.25rem; }
/* General */
.error-message {
  font-family: "Red Hat Display", "Helvetica Neue", Arial, sans-serif;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3.125rem;
  text-align: center; }
  .error-message__figure {
    max-width: 14.125rem;
    margin-bottom: 1.25rem; }
  .error-message__figure-img {
    max-width: 100%;
    height: auto; }
  .error-message__obj-title, .error-message__text {
    font-weight: 700;
    line-height: 1.2em; }
  .error-message__obj-title {
    font-size: 1.75em;
    margin-bottom: 1.5625rem;
    color: #069; }
  .error-message__text {
    font-size: 1.25em;
    color: #424242; }
    .error-message__text a {
      transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
      .error-message__text a:link, .error-message__text a:visited, .error-message__text a:active {
        color: #069; }
        .error-message__text a:link:hover, .error-message__text a:visited:hover, .error-message__text a:active:hover {
          color: #0994DC; }

/********** [-end- MAIN CONTENT STYLES -end-] **********/
/********** [ -start- FOOTER STYLES -start- ] **********/
/* Template */
.main-footer {
  padding: 0 0.9375rem;
  border-top: 2px solid #E0E0E0; }
  .main-footer__top {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1.5625rem 0 1.875rem;
    border-bottom: 1px solid #E0E0E0; }
  .main-footer__top-region {
    display: flex;
    align-items: center;
    flex-direction: row; }
    .main-footer__top-region:first-of-type {
      flex: 1 0 100%;
      max-width: 100%; }
    .main-footer__top-region:last-of-type {
      margin-right: 0;
      margin-left: auto; }
  .main-footer__upper-middle {
    padding: 1.875rem 0;
    border-bottom: 1px solid #E0E0E0; }
  .main-footer__bottom-middle {
    padding: 1.875rem 0; }
  .main-footer__bottom {
    display: flex;
    flex-direction: column; }
  .main-footer__bottom-region {
    padding: 0.9375rem 0; }
    .main-footer__bottom-region:first-of-type {
      background-color: #121212; }
    .main-footer__bottom-region:last-of-type {
      display: flex;
      justify-content: center; }
  .main-footer__developer-signature {
    display: inline-flex;
    margin: 0 auto; }
    .main-footer__developer-signature img,
    .main-footer__developer-signature amp-img {
      width: 7.0625rem;
      height: auto; }

/* Navegación Extendida */
.footer-social-links {
  font-size: 0.875em;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-direction: row;
  text-transform: uppercase;
  color: #121212; }
  .footer-social-links__item {
    margin-right: 1.5625rem; }
    .footer-social-links__item:last-child {
      margin-right: 0; }
  .footer-social-links__link[href*='facebook'] {
    display: inline-flex;
    text-indent: -9999px;
    background-color: #0994DC;
    -o-object-fit: cover;
       object-fit: cover;
    mask: url("/css-custom/239/v3/icons/facebook-f.svg") no-repeat center/auto 100%;
    -webkit-mask: url("/css-custom/239/v3/icons/facebook-f.svg") no-repeat center/auto 100%;
    mask-size: auto 100%;
    -webkit-mask-size: auto 100%;
    min-width: 0.63438rem;
    min-height: 1.14062rem; }
  .footer-social-links__link[href*='twitter'] {
    display: inline-flex;
    text-indent: -9999px;
    background-color: #0994DC;
    -o-object-fit: cover;
       object-fit: cover;
    mask-size: 100 auto;
    -webkit-mask-size: 100 auto;
    min-width: 1.21938rem;
    min-height: 1.0175rem; }
  .footer-social-links__link[href*='instagram'] {
    display: inline-flex;
    text-indent: -9999px;
    background-color: #0994DC;
    -o-object-fit: cover;
       object-fit: cover;
    mask-size: 100 auto;
    -webkit-mask-size: 100 auto;
    min-width: 1.14875rem;
    min-height: 1.15125rem; }
  .footer-social-links__link[href*='youtube'] {
    display: inline-flex;
    text-indent: -9999px;
    background-color: #0994DC;
    -o-object-fit: cover;
       object-fit: cover;
    mask-size: 100 auto;
    -webkit-mask-size: 100 auto;
    min-width: 1.26438rem;
    min-height: 0.91312rem; }
  .footer-social-links__link[href*='linkedin'] {
    display: inline-flex;
    text-indent: -9999px;
    background-color: #0994DC;
    -o-object-fit: cover;
       object-fit: cover;
    mask-size: 100 auto;
    -webkit-mask-size: 100 auto;
    min-width: 1.14875rem;
    min-height: 1.15125rem; }
  .footer-social-links__link[href*='whatsapp'] {
    display: inline-flex;
    text-indent: -9999px;
    background-color: #0994DC;
    -o-object-fit: cover;
       object-fit: cover;
    mask-size: 100 auto;
    -webkit-mask-size: 100 auto;
    min-width: 1.14875rem;
    min-height: 1.15125rem; }
  .footer-social-links__link[href*='facebook-f'] {
    mask: url("/css-custom/239/v3/icons/facebook-f.svg") no-repeat center/100% auto;
    -webkit-mask: url("/css-custom/239/v3/icons/facebook-f.svg") no-repeat center/100% auto; }
  .footer-social-links__link[href*='facebook-circle'] {
    mask: url("/css-custom/239/v3/icons/facebook-circle.svg") no-repeat center/100% auto;
    -webkit-mask: url("/css-custom/239/v3/icons/facebook-circle.svg") no-repeat center/100% auto; }
  .footer-social-links__link[href*='facebook-square'] {
    mask: url("/css-custom/239/v3/icons/facebook-square.svg") no-repeat center/100% auto;
    -webkit-mask: url("/css-custom/239/v3/icons/facebook-square.svg") no-repeat center/100% auto; }
  .footer-social-links__link[href*='instagram'] {
    mask: url("/css-custom/239/v3/icons/instagram.svg") no-repeat center/100% auto;
    -webkit-mask: url("/css-custom/239/v3/icons/instagram.svg") no-repeat center/100% auto; }
  .footer-social-links__link[href*='rss'] {
    mask: url("/css-custom/239/v3/icons/rss.svg") no-repeat center/100% auto;
    -webkit-mask: url("/css-custom/239/v3/icons/rss.svg") no-repeat center/100% auto; }
  .footer-social-links__link[href*='telegram'] {
    mask: url("/css-custom/239/v3/icons/telegram.svg") no-repeat center/100% auto;
    -webkit-mask: url("/css-custom/239/v3/icons/telegram.svg") no-repeat center/100% auto; }
  .footer-social-links__link[href*='telegram-circle'] {
    mask: url("/css-custom/239/v3/icons/telegram-circle.svg") no-repeat center/100% auto;
    -webkit-mask: url("/css-custom/239/v3/icons/telegram-circle.svg") no-repeat center/100% auto; }
  .footer-social-links__link[href*='twitter'] {
    mask: url("/css-custom/239/v3/icons/twitter.svg") no-repeat center/100% auto;
    -webkit-mask: url("/css-custom/239/v3/icons/twitter.svg") no-repeat center/100% auto; }
  .footer-social-links__link[href*='whatsapp'] {
    mask: url("/css-custom/239/v3/icons/whatsapp-inv.svg") no-repeat center/100% auto;
    -webkit-mask: url("/css-custom/239/v3/icons/whatsapp-inv.svg") no-repeat center/100% auto; }
  .footer-social-links__link[href*='youtube'] {
    mask: url("/css-custom/239/v3/icons/youtube.svg") no-repeat center/100% auto;
    -webkit-mask: url("/css-custom/239/v3/icons/youtube.svg") no-repeat center/100% auto; }
  .footer-social-links__link[href*='linkedin'] {
    mask: url("/css-custom/239/v3/icons/linkedin.svg") no-repeat center/100% auto;
    -webkit-mask: url("/css-custom/239/v3/icons/linkedin.svg") no-repeat center/100% auto; }
  .footer-social-links__link[href*='linkedin-square'] {
    mask: url("/css-custom/239/v3/icons/linkedin-square.svg") no-repeat center/100% auto;
    -webkit-mask: url("/css-custom/239/v3/icons/linkedin-square.svg") no-repeat center/100% auto; }
  .footer-social-links__link[href*='spotify'] {
    mask: url("/css-custom/239/v3/icons/spotify.svg") no-repeat center/100% auto;
    -webkit-mask: url("/css-custom/239/v3/icons/spotify.svg") no-repeat center/100% auto; }
.other-links--footer {
  margin-left: auto;
  text-align: center; }
  .other-links--footer .other-links__menu-item {
    font-size: 0.875em;
    font-weight: 500;
    margin-left: 0.3125rem;
    margin-bottom: 0.3125rem;
    text-transform: uppercase;
    color: #121212;
    border: 1px solid #000;
    padding: 0.625rem; }
    .other-links--footer .other-links__menu-item ~ .other-links__menu-item {
      padding-top: 0.3125rem; }

.footer-nav {
  margin: 0 auto;
  padding: 0 0.625rem 0 3.125rem; }
  .footer-nav__page-link {
    flex: 0 0 50%;
    box-sizing: border-box;
    max-width: 50%;
    margin-bottom: 0.9375rem;
    text-transform: uppercase;
    color: #121212; }
    .footer-nav__page-link:nth-child(odd) {
      padding-right: 1.875rem; }
    .footer-nav__page-link:nth-last-child(2), .footer-nav__page-link:last-of-type {
      margin-bottom: 0; }
  .footer-nav__pages {
    font-size: 0.75em;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }

.mediakit-links__page-link {
  font-size: 0.75em;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
  color: #121212; }
  .mediakit-links__page-link:last-of-type {
    margin-bottom: 0; }

.mediakit-links__pages {
  display: flex;
  align-items: center;
  flex-direction: column; }

.mediakit-links__first-word {
  font-weight: 700; }
  .mediakit-links__first-word::after {
    font-weight: 700;
    content: ' - ';
    color: #121212; }

/* Adjuntos */
.brand-logo--footer {
  margin: 0 auto;
  margin-bottom: 1.875rem; }
  .brand-logo--footer img {
    max-width: 8.25rem;
    height: auto; }

/* Contenidos */
.footer-copyright {
  font-size: 0.75em;
  line-height: 1.2em;
  color: #FFF; }
  .footer-copyright > * strong {
    font-weight: 700; }
  .footer-copyright > * a {
    color: #069; }

/********** [-end- FOOTER STYLES -end-] **********/